import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-marketaver',
  templateUrl: './marketaver.component.html',
  styleUrls: ['./marketaver.component.css']
})
export class MarketaverComponent implements OnInit {
  
  @Input('var_desc') var_desc;
  @Input('var_val') var_val;
  @Input('var_units') var_units;
  constructor() { }

  ngOnInit(): void {
  }

}
