import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

import {
  API_FORECAST_DEMAND
} from '../../api-endpoints';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'dam-demand',
  templateUrl: './dam-demand.component.html',
  styleUrls: ['./dam-demand.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class DAMDemandComponent implements OnInit {

  year: string
  month: string

  date: any
  date_start: any
  date_end: any
  data_code: any

  chart: any

  points: any

  
  constructor(private http: HttpClient, private _adapter: DateAdapter<any>, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.points = [];

    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create("chartdivl", am4charts.XYChart);
    let chart = this.chart

    chart.colors.step = 2;

    chart.legend = new am4charts.Legend()
    chart.legend.position = 'bottom'
    chart.legend.paddingBottom = 20
    chart.legend.labels.template.maxWidth = 95
    chart.legend.labels.template.fill = am4core.color("#FFFFFF");

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    xAxis.dataFields.category = 'category'
    xAxis.renderer.cellStartLocation = 0.1
    xAxis.renderer.cellEndLocation = 0.9
    xAxis.renderer.grid.template.location = 0;

    
    

    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;

    xAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    xAxis.renderer.grid.template.stroke = am4core.color("#FFFFFF");
    yAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    yAxis.renderer.grid.template.stroke = am4core.color("#FFFFFF");


    function createSeries(value, name) {
      var series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = value
      series.dataFields.categoryX = 'category'
      series.name = name

      series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX} година: {valueY} МВт.год"

      return series;
    }

    chart.data = [
      {
        category: '1',
        fakt: 0,
        forecast: 0
      }
    ]


    createSeries('fakt', 'Факт');
    createSeries('forecast', 'Прогноз');




    /*chart1.innerRadius = am4core.percent(40);

    chart1.data = [ {
      "country": "ВЕС",
      "litres": 3866,
      "color": am4core.color("#0bbaef")
    }, {
      "country": "СЕС (вклюсно з дСЕС)",
      "litres": 7670,
      "color": am4core.color("#fed501")
    }, {
      "country": "мГЕС",
      "litres": 276,
      "color": am4core.color("#1973bb")
    }, {
      "country": "Біогаз/біомаса",
      "litres": 992,
      "color": am4core.color("#94c11e")
    }];
    
    let pieSeries1 = chart1.series.push(new am4charts.PieSeries());
    pieSeries1.dataFields.value = "litres";
    pieSeries1.dataFields.category = "country";
    pieSeries1.slices.template.propertyFields.fill = "color";
    pieSeries1.labels.template.fill = am4core.color("white");

    pieSeries1.slices.template.stroke = am4core.color("#fff");
    pieSeries1.slices.template.strokeWidth = 2;
    pieSeries1.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries1.hiddenState.properties.opacity = 1;
    pieSeries1.hiddenState.properties.endAngle = -90;
    pieSeries1.hiddenState.properties.startAngle = -90;

   
    // This creates initial animation
    pieSeries1.hiddenState.properties.opacity = 1;
    pieSeries1.hiddenState.properties.endAngle = -90;
    pieSeries1.hiddenState.properties.startAngle = -90;

    //legend
    chart1.legend = new am4charts.Legend();


    var legendContainer = am4core.create("clegend", am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    chart1.legend.parent = legendContainer;
    chart1.legend.labels.template.fill = am4core.color("#fff");
    chart1.legend.valueLabels.template.fill = am4core.color("#fff"); 

    pieSeries1.labels.template.disabled = true;
    pieSeries1.ticks.template.disabled = true;*/
    //



  }


  updateDate(event) {
    this.date = this.datepipe.transform(event.value, 'dd/MM/yyyy')
    this.date_start = this.date
    this.date_end = this.date
    this.createRequest()
  }

  createRequest() {
    console.log('request')

    this.http.get<any>(API_FORECAST_DEMAND, {
      params: {
        dt: this.date
      },
      observe: 'response'
    }).subscribe(response => {
      //      this.data = response.body;
      /*this.chart.data = [
        {
          category: '1',
          fakt: 40,
          forecast: 155
        },
        {
          category: '2',
          fakt: 30,
          forecast: 178
        },
        {
          category: '3',
          fakt: 27,
          forecast: 140
        },
        {
          category: '4',
          fakt: 50,
          forecast: 133
        }
      ]*/

      let data = response.body;
      let res = [];
      for(let i = 0; i < data.length; i++) {
        res.push({
          category: data[i].hour,
          fakt: data[i].fakt,
          forecast: data[i].forecast
        });
      }
      this.chart.data = res;

      

      this.points = data;

      //this.data = data;

      /*let dat = JSON.parse(JSON.stringify(response.body))
      let dataChart = []
      this.graphVals = []
      for (let i = 0; i < dat.length; i++) {
        let date = new Date(dat[i].date);
        let valproc = ((dat[i].value4 - dat[i].value3) / dat[i].value4 * 100).toFixed(1);
        if (parseInt(valproc) >= 100 || valproc == 'NaN') {
          valproc = ''
        }
        else {
          valproc += ' %'
        }

        dataChart.push({ date: date, value1: dat[i].value1, value2: dat[i].value2, value3: dat[i].value3, value4: dat[i].value4 });
        this.graphVals.push({ hour: i + 1, val: dat[i].value4, valproc: valproc });
      }

      console.log(this.graphVals)

      var dateMomentObject = moment(this.date, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
      let date1 = this.addDays(dateObject, -2)
      let date2 = this.addDays(dateObject, -1)
      let date3 = dateObject

      this.chart.data = dataChart;
      this.series1.name = "факт на " + moment(date1).format("DD/MM/YYYY")
      this.series2.name = "факт на " + moment(date2).format("DD/MM/YYYY")
      this.series3.name = "факт на " + moment(date3).format("DD/MM/YYYY")
      this.series4.name = "прогноз на " + moment(date3).format("DD/MM/YYYY")
      //      console.log(dataChart.length)
      return response.body*/
    });
  }


}
