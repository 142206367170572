import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {FormControl} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;

import {
  API_TABLETS
} from '../../api-endpoints';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-tablets',
  templateUrl: './tablets.component.html',
  styleUrls: ['./tablets.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],       
})
export class TabletsComponent implements OnInit {

  year: string
  month: string
  date = new FormControl(moment());
  ds: any

  dateRequest: any

  chart1: any
  chart2: any

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    am4core.useTheme(am4themes_animated);

    this.chart1 = am4core.create("chartdiv1", am4charts.PieChart);
    this.chart1.innerRadius = am4core.percent(40);

    let pieSeries1 = this.chart1.series.push(new am4charts.PieSeries());
    pieSeries1.dataFields.value = "val";
    pieSeries1.dataFields.category = "name";
    pieSeries1.slices.template.propertyFields.fill = "color";
    pieSeries1.labels.template.fill = am4core.color("white");

    pieSeries1.slices.template.stroke = am4core.color("#fff");
    pieSeries1.slices.template.strokeWidth = 2;
    pieSeries1.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries1.hiddenState.properties.opacity = 1;
    pieSeries1.hiddenState.properties.endAngle = -90;
    pieSeries1.hiddenState.properties.startAngle = -90;

    this.chart2 = am4core.create("chartdiv2", am4charts.PieChart);
    this.chart2.innerRadius = am4core.percent(40);

    let pieSeries2 = this.chart2.series.push(new am4charts.PieSeries());
    pieSeries2.dataFields.value = "val";
    pieSeries2.dataFields.category = "name";
    pieSeries2.slices.template.propertyFields.fill = "color";
    pieSeries2.labels.template.fill = am4core.color("white");

    pieSeries2.slices.template.stroke = am4core.color("#fff");
    pieSeries2.slices.template.strokeWidth = 2;
    pieSeries2.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries2.hiddenState.properties.opacity = 1;
    pieSeries2.hiddenState.properties.endAngle = -90;
    pieSeries2.hiddenState.properties.startAngle = -90;

    this.createRequests();
  }

  createRequestTablets(coll){
    console.log('request')
    this.http.get<any>(API_TABLETS, {
      params:{
        ds: this.ds,
        datacoll: coll
      },
      observe: 'response'}).subscribe(response => {
      console.log(response.body)
      let dat = JSON.parse(JSON.stringify(response.body))
      let dataChart = []
      for (let i = 0; i < dat.length; i++){
        dataChart.push({name:dat[i].name, val: dat[i].val, color: am4core.color(dat[i].color)});
      }

      if (coll == 1){
        this.chart1.data = dataChart;
      }

      if (coll == 2){
        this.chart2.data = dataChart;
      }
      
      return response.body
    });
  }

  createRequests(){
    this.createRequestTablets(1)
    this.createRequestTablets(2)
    return;
    this.chart1.data = [ {
      "country": "АЕС",
      "litres": 13835,
      "color": am4core.color("#5226CC")
    }, {
      "country": "ТЕС ГК",
      "litres": 21842,
      "color": am4core.color("#F0692F")
    }, {
      "country": "ТЕЦ",
      "litres": 6070,
      "color": am4core.color("#FF9F4B")
    }, {
      "country": "ГЕС",
      "litres": 4813,
      "color": am4core.color("#028AFF")
    }, {
      "country": "ГАЕС",
      "litres": 1488,
      "color": am4core.color("#0064BA")
    }, {
      "country": "СЕС",
      "litres": 5154,
      "color": am4core.color("#F4D220")
    }, {
      "country": "ВЕС",
      "litres": 1110,
      "color": am4core.color("#4ED1E3")
    }, {
      "country": "Біопаливо",
      "litres": 188,
      "color": am4core.color("#4EE3A4")
    }];

  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;

    let year = normalizedYear.year()
    this.year = year.toString()
    
    ctrlValue.year(year);
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.date.value;
    let month = normalizedMonth.month()
    this.month = (month + 1).toString()
    if (this.month.length < 2)
    {
      this.month = '0' + this.month
    }
    
    ctrlValue.month(month);
    this.date.setValue(ctrlValue);
    this.ds = '01/' + this.month + '/' + this.year
//    this.mapComponent.dtPrice = '01/' + this.month + '/'+ this.year;
    this.createRequests();
  }  


}
