<div class="divdate">
    <mat-form-field appearance="fill">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" (dateChange)="updateDate($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>

<div class="row">
    <h3 style="margin: auto;">Прогноз попиту РДН</h3>
</div>

<div class="row row-sm mb-3">
    <div class="col-sm-12">
        <div id="chartdivl" style="width: 100%; height: 600px"></div>

    </div>
    <div class="col-sm-12">
        <div class="row myrow">
            <div class="col-sm-1"></div>
            <div class="col-sm-2"><i>Година</i></div>
            <div class="col-sm-2"><i>Факт</i></div>
            <div class="col-sm-2"><i>Прогноз</i></div>
            <div class="col-sm-2"><i>Абсолютне відхилення</i></div>
            <div class="col-sm-2"><i>Відносне відхилення</i></div>
        </div>
        <div *ngFor="let item of points" class="row myrow">
            <div class="col-sm-1"></div>
            <div class="col-sm-2">{{item.hour}}</div>
            <div class="col-sm-2">{{item.fakt}}</div>
            <div class="col-sm-2">{{item.forecast}}</div>
            <div class="col-sm-2">{{item.diff}}</div>
            <div class="col-sm-2" [ngClass]="{
                'positive' : item.diff > 0,
                'negative' : item.diff < 0
             }">{{item.diff_p}}</div>
        <div>
    </div>
</div>