<div class="divdate">
    <mat-form-field appearance="fill">
        <mat-label>{{ 'SHARED.SELECTMONTH' | translate }}</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp
                        startView="multi-year"
                        (yearSelected)="chosenYearHandler($event)"
                        (monthSelected)="chosenMonthHandler($event, dp)"
                        panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>    
</div>

<div class="row"><h3 style="margin: auto;">{{ 'HEADERS.CAPACITY' | translate }}</h3></div>

<div class="row row-sm mb-3">
    <div class="col-sm-6 col-md-4">
        <div class="card">
            <div class="card-body center">
                <h3 class="card-title">АЕС</h3>
                <img src="../../../assets/image/AES.jpg">
                <h3 class="card-">13835 МВт</h3>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-md-4">
        <div class="card">
            <div class="card-body center">
                <h3 class="card-title">ТЕС ГК</h3>
                <img src="../../../assets/image/TES.jpg">
                <h3 class="card-">21842 МВт</h3>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-md-4">
        <div class="card">
            <div class="card-body center">
                <h3 class="card-title">ГЕС</h3>
                <img src="../../../assets/image/GES.jpg">
                <h3 class="card-">4813 МВт</h3>
            </div>
        </div>
    </div>
</div>
<div class="row row-sm mb-3">
    <div class="col-12">
        <div class="card">
            <div class="row">
                <div class="card-body center">
                    <h3 class="card-title">ГАЕС</h3>
                    <img src="../../../assets/image/GAES.jpg">
                    <h3 class="card-">1488 МВт</h3>
                </div>
                <div class="card-body center">
                    <h3 class="card-title">ТЕЦ</h3>
                    <img src="../../../assets/image/TEC.jpg">
                    <h3 class="card-">6070 МВт</h3>
                </div>
                <div class="card-body center">
                    <h3 class="card-title">СЕС</h3>
                    <img src="../../../assets/image/SUN.jpg">
                    <h3 class="card-">5154 МВт</h3>
                </div>
                <div class="card-body center">
                    <h3 class="card-title">ВЕС</h3>
                    <img src="../../../assets/image/WIND.jpg">
                    <h3 class="card-">1110 МВт</h3>
                </div>
                <div class="card-body center">
                    <h3 class="card-title">Біопаливо</h3>
                    <img src="../../../assets/image/BIO.jpg">
                    <h3 class="card-">188 МВт</h3>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row row-sm mb-3">
    <div class="col-sm-6">
        <h2 class="card-title">Встановлена потужність</h2>
        <h2 class="card-title">ОЕС України</h2>
        <h2 class="card-title">54498.20 МВт</h2>
        <div id="chartdivl" style="width: 100%; height: 400px"></div>
    </div>
    <div class="col-sm-6">
        <h2 class="card-title">Потужність обладнання</h2>
        <h2 class="card-title">ВДЕ</h2>
        <h2 class="card-title">6451.30 МВт</h2>
        <div id="chartdiv2" style="width: 100%; height: 400px"></div>
    </div>
</div>


