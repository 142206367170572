import { Component, EventEmitter, Output, Input, Inject, NgZone, PLATFORM_ID, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';


// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
//import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4geodata_UAHigh from "@amcharts/amcharts4-geodata/ukraineHigh";

//import am4geodata_lang_UK from "@amcharts/amcharts4-geodata/lang/FR"; 
import {TranslatePipe, TranslateService } from '@ngx-translate/core';
//import * as EventEmitter from 'events'

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})


export class ChartComponent {

  @Input('map_type') map_type;
  @Output() onNewArea: EventEmitter<any> = new EventEmitter<any>();

  data: any



  private chart: am4maps.MapChart;
//  let chart: any = {};

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone, public translate: TranslateService) { 

  }

  MapClick = (event) => {
    console.log(event.target.dataItem.dataContext.id);
    var id = event.target.dataItem.dataContext.id;
    id = id.replace('UA-','');
    var nID = parseInt(id);

    console.log(nID);
    this.onNewArea.emit(nID);

  }


   // Run the function only in the browser
   browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit(): void {
 //   console.log('Init1')
    setTimeout(() => {this.data = [{"id": "UA-32", "title": this.translate.instant('UA_REGION.OBLKIYV')}]},500)
  //  console.log('Init2')
  }

  ngAfterViewInit() {
    setTimeout(() => {

    // Chart code goes in here
    this.browserOnly(() => {
//      am4core.useTheme(am4themes_animated);

      am4core.options.autoDispose = true;

      let chart = am4core.create("chartdiv", am4maps.MapChart);
      chart.geodata = am4geodata_UAHigh;
//      chart.geodataNames = am4geodata_lang_UK;
      
      chart.projection = new am4maps.projections.Miller();

      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
      polygonSeries.useGeodata = true

      let polygonTemplate = polygonSeries.mapPolygons.template;

      polygonTemplate.tooltipText = "{title}";

      console.log('AfterInit')

      var data = [
      {"id": "UA-32", "title": this.translate.instant('UA_REGION.OBLKIYV')},
      {"id": "UA-30", "title": this.translate.instant('UA_REGION.KIYV')},
      {"id": "UA-18", "title": this.translate.instant('UA_REGION.OBLZIT')},
      {"id": "UA-56", "title": this.translate.instant('UA_REGION.OBLRIV')},
      {"id": "UA-07", "title": this.translate.instant('UA_REGION.OBLVOL')},
      {"id": "UA-46", "title": this.translate.instant('UA_REGION.OBLLVI')},
      {"id": "UA-21", "title": this.translate.instant('UA_REGION.OBLZAK')},
      {"id": "UA-26", "title": this.translate.instant('UA_REGION.OBLIFR')},
      {"id": "UA-61", "title": this.translate.instant('UA_REGION.OBLTER')},
      {"id": "UA-77", "title": this.translate.instant('UA_REGION.OBLCHERNIVCI')},
      {"id": "UA-68", "title": this.translate.instant('UA_REGION.OBLKHM')},
      {"id": "UA-05", "title": this.translate.instant('UA_REGION.OBLVIN')},
      {"id": "UA-71", "title": this.translate.instant('UA_REGION.OBLCHERK')},
      {"id": "UA-74", "title": this.translate.instant('UA_REGION.OBLCHERNIGIV')},
      {"id": "UA-59", "title": this.translate.instant('UA_REGION.OBLSUM')},
      {"id": "UA-53", "title": this.translate.instant('UA_REGION.OBLPOL')},
      {"id": "UA-63", "title": this.translate.instant('UA_REGION.OBLXAR')},
      {"id": "UA-09", "title": this.translate.instant('UA_REGION.OBLLUG')},
      {"id": "UA-14", "title": this.translate.instant('UA_REGION.OBLDON')},
      {"id": "UA-12", "title": this.translate.instant('UA_REGION.OBLDNI')},
      {"id": "UA-35", "title": this.translate.instant('UA_REGION.OBLKIR')},
      {"id": "UA-51", "title": this.translate.instant('UA_REGION.OBLODE')},
      {"id": "UA-48", "title": this.translate.instant('UA_REGION.OBLMIK')},
      {"id": "UA-65", "title": this.translate.instant('UA_REGION.OBLXER')},
      {"id": "UA-23", "title": this.translate.instant('UA_REGION.OBLZAP')},
      {"id": "UA-43", "title": this.translate.instant('UA_REGION.OBLCRI')},
      {"id": "UA-40", "title": this.translate.instant('UA_REGION.OBLSEV')}
      ]
  
      polygonSeries.data = data;

      polygonTemplate.events.on("hit", this.MapClick);
      polygonTemplate.polygon.fillOpacity = 0.6;
      polygonTemplate.fill = am4core.color("#3a414c");
      polygonTemplate.stroke = am4core.color("#656b72");
//      polygonTemplate.stroke = am4core.color("#333333");

      let hs = polygonTemplate.states.create("hover");
      
      hs.properties.fill = am4core.color("#da2060");

      this.chart = chart;

//      console.log(this.chart)
//      console.log('End')
    });
  }, 0)
  }

/*
  ngOnDestroy() {
    
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      am4core.disposeAllCharts();
      if (this.chart) {
        console.log('ngOnDestroy start')
        console.log(this.chart)
        this.chart.dispose();
        delete this.chart
        this.chart = null
        console.log(this.chart)
        console.log('ngOnDestroy end')
      }
    });
  }
  */

}
