import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-marketarea',
  templateUrl: './marketarea.component.html',
  styleUrls: ['./marketarea.component.css']
})
export class MarketareaComponent implements OnInit {

  @Input('var_desc') var_desc;

  constructor() { }

  ngOnInit(): void {
  }

}
