import { Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DatePipe } from '@angular/common';
import {DateAdapter} from '@angular/material/core';

import {
  API_WHOLESALE_MARKET_BLOCKS, API_WHOLESALE_MARKET_BLOCKSARCHIV
} from '../../api-endpoints';


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import {FormControl} from '@angular/forms';

export interface BlocksList{
  id: number
  name: string
  val: string
}


@Component({
  selector: 'app-wholesale-market-blocks',
  templateUrl: './wholesale-market-blocks.component.html',
  styleUrls: ['./wholesale-market-blocks.component.css']
})




export class WholesaleMarketBlocksComponent implements OnInit {
  
  dtStart: string;
  dtEnd: string;

  data: any
  public blockList: BlocksList[] = []

  chart: any
  dataChart: any = [];
  
  dateS: any
  dateE: any  

  blockArchiv: any

  constructor(private http: HttpClient, private _adapter: DateAdapter<any> , public datepipe: DatePipe) {   }

  ngOnInit(): void {
    am4core.useTheme(am4themes_animated);
    this.chart = am4core.create("chartdiv", am4charts.XYChart);
    this.chart.data = this.dataChart;

    // Create axes
    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());

    dateAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    dateAxis.renderer.grid.template.stroke = am4core.color("#FFFFFF");

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    valueAxis.renderer.grid.template.stroke = am4core.color("#FFFFFF");

    // Create series
    let series = this.chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.tooltipText = "{value}"

    series.tooltip.pointerOrientation = "vertical";

    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.snapToSeries = series;
    this.chart.cursor.xAxis = dateAxis;

    //chart.scrollbarY = new am4core.Scrollbar();
    this.chart.scrollbarX = new am4core.Scrollbar();


    this._adapter.setLocale('ukr');
    this.initDate()

    console.log(this.dtStart)
    console.log(this.dtEnd)

//    this.blockClick(1)
    this.createRequestBlocks()
    this.createRequest()
  }

  createRequest()  {
    setInterval( () => {
      this.createRequestBlocks()
  }, 30000);
}

initDate(): void {
  var dateSString = localStorage.getItem('dtS')
  console.log(dateSString)
  if (dateSString === undefined || dateSString === null)
    dateSString = '01.01.2021'

  if (dateSString.length == 10) {
    var arrS = dateSString.split(".")
    this.dateS = new FormControl(new Date(parseInt(arrS[2]), parseInt(arrS[1]) - 1, parseInt(arrS[0])));
    var mon = parseInt(arrS[1]) - 1
    this.dtStart = arrS[0] + '/' + arrS[1] + '/' + arrS[2]
  }
  else {
    this.dateS = new FormControl(new Date());
  }

  var dateEString = localStorage.getItem('dtE')

  if (dateEString === undefined || dateEString === null)
    dateEString = '01.01.2021'

  if (dateEString.length == 10) {
    var arrE = dateEString.split(".")
    this.dateE = new FormControl(new Date(parseInt(arrE[2]), parseInt(arrE[1]) - 1, parseInt(arrE[0])));
    this.dtEnd = arrE[0] + '/' + arrE[1] + '/' + arrE[2]
  }
  else {
    this.dateE = new FormControl(new Date());
  }
}

  createRequestBlocks()
  {
    console.log('createRequestBlocks')

    this.http.get<any>(API_WHOLESALE_MARKET_BLOCKS, {
      params:{
      },
      observe: 'response'}).subscribe(response => {
      this.data = response.body;
 //     console.log('***')
 //     console.log(response.body)
      this.blockList = JSON.parse(JSON.stringify(response.body))
 //     console.log('---')
      return response.body
    });
    return this.data
  }

  blockClick(id){
    this.blockArchiv = id
    this.requestArchiv()
  }

  requestArchiv(){
    this.http.get<any>(API_WHOLESALE_MARKET_BLOCKSARCHIV, {
      params:{
        ds: this.dtStart,
        de: this.dtEnd,
        datacoll: this.blockArchiv
      },
      observe: 'response'}).subscribe(response => {
      this.data = response.body;
      console.log(response.body.lenght)
      let dat = JSON.parse(JSON.stringify(response.body))
      let dataChart = []
      for (let i = 0; i < dat.length; i++){
        let date = new Date(dat[i].date); 
        dataChart.push({date:date, value: dat[i].value});
      }

      this.chart.data = dataChart;
      console.log(dataChart.length)
      return response.body
    });

  }


  blockClick1(id){
    console.log(id)

    let dataChart = []

    let value = 50;
    for(var i = 0; i < 300; i++){
      let date = new Date();
      date.setHours(0,0,0,0);
      date.setDate(i);
      value -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      dataChart.push({date:date, value: value});
    }
    this.chart.data = dataChart;
  }

  saveToLocalStorage()
  {
    var dtLocStorS = this.dtStart
    var dtLocStorE = this.dtEnd

    for (var i = 0; i < 2; i++)
    {
      dtLocStorS = dtLocStorS.replace('/','.')
      dtLocStorE = dtLocStorE.replace('/','.')
    }
    localStorage.setItem('dtS', dtLocStorS)
    localStorage.setItem('dtE', dtLocStorE)
  }

  setStart(event: any): void {
    if (event.value != null)
    {
      console.log("Start", event.value);
      this.dtStart = this.datepipe.transform(event.value, 'dd/MM/yyyy')
    }
  }

  setEnd(event: any): void {
    if (event.value != null)
    {
      this.dtEnd = this.datepipe.transform(event.value, 'dd/MM/yyyy')
      this.requestArchiv();
      this.saveToLocalStorage()
    }
  }

  addNewDate(event: any): void {
    console.log("addNewDate", event.value);

  }


}
