import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-markettype',
  templateUrl: './markettype.component.html',
  styleUrls: ['./markettype.component.css']
})
export class MarkettypeComponent implements OnInit {
  
  @Input('date_s') date_s;
  @Input('date_e') date_e;
  @Input('data_codeExcel') data_codeExcel;

  @Input('var_desc') var_desc;

  constructor() { }

  

  ngOnInit(): void {
  }

}
