<div class="divdate">
    <mat-form-field appearance="fill">
        <mat-label>{{ 'SHARED.SELECTPERIOD' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker" (dateChange)="addNewDate($event)" >
          <input matStartDate placeholder="Start date" (dateChange)="setStart($event)" [value]="dateS.value">
          <input matEndDate placeholder="End date" (dateChange)="setEnd($event)" [value]="dateE.value">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-3 divmap" >
            <div *ngFor="let block of blockList">
                <div class="row block_row" >
                    <div class="col-lg-6">
                        <span class="pseudolink"  (click)="blockClick(block.id)">{{block.name}}</span>
                    </div>
                    <div class="col-lg-6">
                        <span>{{block.val}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9 osrdiv">
            <div id="chartdiv" style="width: 100%; height: 400px"></div>
        </div>
    </div>
</div>    

