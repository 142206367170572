<div class="divdate">
    <mat-form-field appearance="fill">
        <mat-label>{{ 'SHARED.SELECTPERIOD' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker" (dateChange)="addNewDate($event)" >
          <input matStartDate placeholder="Start date" (dateChange)="setStart($event)" [value]="dateS.value">
          <input matEndDate placeholder="End date" (dateChange)="setEnd($event)" [value]="dateE.value">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
  

  <div class="row"><h3 style="margin: auto;">{{ 'HEADERS.PRICES' | translate }}</h3></div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6">
          <div class="container-fluid">
            <app-marketblockrdd
              [market_name] = "'SHARED.RDD'"
              [data_codeExcel] = "-1"
              [date_s] = "this.dtStart"
              [date_e] = "this.dtEnd"

              [area1_name] = "'SHARED.OES'"
    
              [var_desc11] = "'base'"
              [var_val11] = "BASE1"
              [var_units11] = "'SHARED.HRRMWTH'"  
    
              [var_desc12] = "'peak'"
              [var_val12] = "PEAK1"
              [var_units12] = "''"  
    
              [var_desc13] = "'offpeak'"
              [var_val13] = "OFFP1"
              [var_units13] = "''"  
              
              [var_desc_b1_1_7] = "'1-7'"
              [var_val_b1_1_7] = "B1_1_7"
              [var_units_b1_1_7] = "'години'"  

              [var_desc_b1_8_22] = "'8-22'"
              [var_val_b1_8_22] = "B1_8_22"
              [var_units_b1_8_22] = ""  

              [var_desc_b1_23_24] = "'23-24'"
              [var_val_b1_23_24] = "B1_23_24"
              [var_units_b1_23_24] = ""  

              [area2_name] = "'SHARED.BUR'"
    
              [var_desc21] = "'base'"
              [var_val21] = "BASE2"
              [var_units21] = "''"  
    
              [var_desc22] = "'peak'"
              [var_val22] = "PEAK2"
              [var_units22] = "''"  
    
              [var_desc23] = "'offpeak'"
              [var_val23] = "OFFP2"
              [var_units23] = "''"  
    
              [var_desc_b2_1_7] = "'1-7'"
              [var_val_b2_1_7] = "B2_1_7"
              [var_units_b2_1_7] = ""  

              [var_desc_b2_8_22] = "'8-22'"
              [var_val_b2_8_22] = "B2_8_22"
              [var_units_b2_8_22] = ""  

              [var_desc_b2_23_24] = "'23-24'"
              [var_val_b2_23_24] = "B2_23_24"
              [var_units_b2_23_24] = ""  

            ></app-marketblockrdd>
    
            <app-marketblock
              [market_name] = "'SHARED.RDN'"
              [data_codeExcel] = "2"
              [date_s] = "this.dtStart"
              [date_e] = "this.dtEnd"
    
              [area1_name] = "'SHARED.OES'"
    
              [var_desc11] = "'1-24'"
              [var_val11] = "BASE3"
              [var_units11] = "'SHARED.HRRMWTH'"  
    
              [var_desc12] = "'8-23'"
              [var_val12] = "PEAK3"
              [var_units12] = "''"  
    
              [var_desc13] = "'1-7, 24'"
              [var_val13] = "OFFP3"
              [var_units13] = "''"  
    
              [var_desc_aver1] = "'SHARED.AVER'"
              [var_val_aver1] = "AVER3"
              [var_units_aver1] = "'SHARED.HRRMWTH'"  
    
              [area2_name] = "'SHARED.BUR'"
    
              [var_desc21] = "'1-24'"
              [var_val21] = "BASE4"
              [var_units21] = "''"  
    
              [var_desc22] = "'8-23'"
              [var_val22] = "PEAK4"
              [var_units22] = "''"  
    
              [var_desc23] = "'1-7, 24'"
              [var_val23] = "OFFP4"
              [var_units23] = "''"  
    
              [var_desc_aver2] = "'SHARED.AVER'"
              [var_val_aver2] = "AVER4"
              [var_units_aver2] = "'SHARED.HRRMWTH'"  
            ></app-marketblock>
    
            <app-marketblock
              [market_name] = "'SHARED.VDR'"
              [data_codeExcel] = "3"
              [date_s] = "this.dtStart"
              [date_e] = "this.dtEnd"

              [area1_name] = "'SHARED.OES'"
    
              [var_desc11] = "'1-24'"
              [var_val11] = "BASE5"
              [var_units11] = "'SHARED.HRRMWTH'"  
    
              [var_desc12] = "'8-23'"
              [var_val12] = "PEAK5"
              [var_units12] = "''"  
    
              [var_desc13] = "'1-7, 24'"
              [var_val13] = "OFFP5"
              [var_units13] = "''"  
    
              [var_desc_aver1] = "'SHARED.AVER'"
              [var_val_aver1] = "AVER5"
              [var_units_aver1] = "'SHARED.HRRMWTH'"  
    
              [area2_name] = "'SHARED.BUR'"
    
              [var_desc21] = "'1-24'"
              [var_val21] = "BASE6"
              [var_units21] = "''"  
    
              [var_desc22] = "'8-23'"
              [var_val22] = "PEAK6"
              [var_units22] = "''"  
    
              [var_desc23] = "'1-7, 24'"
              [var_val23] = "OFFP6"
              [var_units23] = "''"  
    
              [var_desc_aver2] = "'SHARED.AVER'"
              [var_val_aver2] = "AVER6"
              [var_units_aver2] = "'SHARED.HRRMWTH'"  
            ></app-marketblock>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="container-fluid">
            <app-marketblockright
              [market_name] = "'SHARED.BR'"
              [data_codeExcel] = "4"
              [date_s] = "this.dtStart"
              [date_e] = "this.dtEnd"

              [area1_name] = "'SHARED.OES'"
    
              [var_desc_aver1] = "'SHARED.UP'"
              [var_val_aver1] = "AVER7"
              [var_units_aver1] = "'SHARED.HRRMWTH'"  

              [var_desc_aver2] = "'SHARED.DOWN'"
              [var_val_aver2] = "AVER8"
              [var_units_aver2] = "''"  
              
              [area2_name] = "'SHARED.BUR'"
    
              [var_desc_aver3] = "'SHARED.UP'"
              [var_val_aver3] = "AVER9"
              [var_units_aver3] = "''"  

              [var_desc_aver4] = "'SHARED.DOWN'"
              [var_val_aver4] = "AVER10"
              [var_units_aver4] = "''"  

            ></app-marketblockright>
            <!--
            <app-marketblockright
              [market_name] = "'SHARED.RDP'"
              [data_codeExcel] = "-1"
              [date_s] = "this.dtStart"
              [date_e] = "this.dtEnd"

              [area1_name] = "'SHARED.OES'"
    
              [var_desc_aver1] = "'SHARED.UP'"
              [var_val_aver1] = "AVER11"
              [var_units_aver1] = "'SHARED.HRRMWTH'"  

              [var_desc_aver2] = "'SHARED.DOWN'"
              [var_val_aver2] = "AVER12"
              [var_units_aver2] = "''"  
              
              [area2_name] = "'SHARED.BUR'"
    
              [var_desc_aver3] = "'SHARED.UP'"
              [var_val_aver3] = "AVER13"
              [var_units_aver3] = "''"  

              [var_desc_aver4] = "'SHARED.DOWN'"
              [var_val_aver4] = "AVER14"
              [var_units_aver4] = "''"  

            ></app-marketblockright>
            -->
            <div class="row"  style="height:70px;">
                <app-download-exel
                [date_start] = "this.dtStart"
                [date_end] = "this.dtEnd"
                [data_code] = "99"
                ></app-download-exel>
            </div>
            

            <div class="row"><h5>base: 1-24 години виконання</h5></div>
            <div class="row"><h5>peak: 9-20 години виконання у робочі дні</h5></div>
            <div class="row"><h5>offpeak: 1-8, 21-24 години виконання у робочі дні, 1-24 години виконання у вихідні дні</h5></div>
          </div>
        </div>
      </div>
    </div>
    
    
    
