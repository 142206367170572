import { Input, Component, OnInit, ChangeDetectorRef } from '@angular/core';

import {OSRList} from '../../pages/retail-market-generalinfo/retail-market-generalinfo.component';

@Component({
  selector: 'app-pup',
  templateUrl: './pup.component.html',
  styleUrls: ['./pup.component.css']
})
export class PupComponent implements OnInit {

  @Input() pupList: OSRList[];
  @Input() pupChange:boolean;
  @Input('col1Name') col1Name;
  
  constructor(private ref: ChangeDetectorRef) { 
    setInterval(() => {
      // the following is required, otherwise the view will not be updated
      if (this.pupChange === true)
      {
        this.ref.markForCheck();
        this.pupChange = false;
       }}, 100);  

  }

  ngOnInit(): void {
    this.pupChange = false
  }

}
