import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from '../auth/guards/auth.guard';
import {LoginComponent} from '../auth/login/login.component';
import {MainComponent} from '../core/main/main.component';
import { WholesaleMarketVolumesComponent } from '../pages/wholesale-market-volumes/wholesale-market-volumes.component';
import { WholesaleMarketPricesComponent } from '../pages/wholesale-market-prices/wholesale-market-prices.component';
import { RetailMarketGeneralinfoComponent } from '../pages/retail-market-generalinfo/retail-market-generalinfo.component';
import { RetailMarketMappriceComponent } from '../pages/retail-market-mapprice/retail-market-mapprice.component';
import { WholesaleMarketInstallcapacityComponent } from '../pages/wholesale-market-installcapacity/wholesale-market-installcapacity.component';
import { VDEGeneralComponent } from '../pages/vde-general/vde-general.component';
import { DAMDemandComponent } from '../pages/dam-demand/dam-demand.component';
import { WholesaleMarketBlocksComponent } from '../pages/wholesale-market-blocks/wholesale-market-blocks.component';
import { ForecastRdnComponent } from '../pages/forecast-rdn/forecast-rdn.component';
import { ManufacturersComponent } from '../pages/manufacturers/manufacturers.component';
import { TabletsComponent } from '../pages/tablets/tablets.component';
import { MaininfoComponent } from '../pages/maininfo/maininfo.component';



const routes: Routes = [
  //{ path: '', component: MainComponent, canActivate: [AuthGuard] },
  { path: '', component: MaininfoComponent },
  { path: 'wholesale-market-prices', component: WholesaleMarketPricesComponent, canActivate: [AuthGuard] },
  { path: 'wholesale-market-volumes', component: WholesaleMarketVolumesComponent, canActivate: [AuthGuard] },
  { path: 'retail-market-generalinfo', component: RetailMarketGeneralinfoComponent, canActivate: [AuthGuard] },
  { path: 'retail-market-mapprice', component: RetailMarketMappriceComponent, canActivate: [AuthGuard] },
  { path: 'wholesale-market-installcapacity', component: WholesaleMarketInstallcapacityComponent, canActivate: [AuthGuard] },
  { path: 'vde-general', component: VDEGeneralComponent, canActivate: [AuthGuard] },
  { path: 'dam-demand', component: DAMDemandComponent, canActivate: [AuthGuard] },
  { path: 'wholesale-market-blocks', component: WholesaleMarketBlocksComponent, canActivate: [AuthGuard] },
  { path: 'forecast-rdn', component: ForecastRdnComponent, canActivate: [AuthGuard] },
  { path: 'manufacturers', component: ManufacturersComponent, canActivate: [AuthGuard] },
  { path: 'tablets', component: TabletsComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
//  { path: 'main', component: MainComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
