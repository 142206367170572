import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import {JWT_LOGIN, JWT_REFRESH_TOKEN} from '../../api-endpoints';
import {Tokens} from '../models/token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly TM_TOKEN = 'TM_TOKEN';
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;

  constructor(private http: HttpClient) {}

  login(user: { username: string, password: string }): Observable<boolean> {
    return this.http.post<any>(JWT_LOGIN, user)
      .pipe(
        tap(tokens => this.doLoginUser(user.username, tokens)),
        mapTo(true),
        catchError(error => {
          alert(error.error);
          return of(false);
        }));
  }

  logout() {
    this.doLogoutUser();
    location.reload();
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    return this.http.post<any>(JWT_REFRESH_TOKEN, {
      'refresh': this.getRefreshToken()
    }).pipe(tap((tokens: Tokens) => {
      this.storeJwtToken(tokens.access);
    }));
  }

  getJwtToken() {
    var tm_tok = localStorage.getItem(this.TM_TOKEN);
    if (!!tm_tok === false){
      return ''
    }

    var curDt = new Date().getTime()
    if (curDt - parseInt(tm_tok) > 6 * 60 * 60 * 1000){
      return ''
    }

    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, tokens: Tokens) {
    localStorage.setItem(this.TM_TOKEN, new Date().getTime().toString());
    this.loggedUser = username;
    this.storeTokens(tokens);
    console.log('doLoginUser');
    this.saveToLocalStorage();
  }

  saveToLocalStorage()
  {
    var dt = new Date();
    dt.setDate(dt.getDate() - 1);
    let szLocStor = this.formattedDate(dt);
    localStorage.setItem('dtS', szLocStor)
    localStorage.setItem('dtE', szLocStor)
  }

  formattedDate(d) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return `${day}.${month}.${year}`;
  }


  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(access: string) {
    localStorage.setItem(this.JWT_TOKEN, access);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.access);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refresh);
    localStorage.setItem(this.TM_TOKEN, new Date().getTime().toString());
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
