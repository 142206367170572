import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-marketitem',
  templateUrl: './marketitem.component.html',
  styleUrls: ['./marketitem.component.css']
})

export class MarketitemComponent implements OnInit {

  @Input('var_desc') var_desc;
  @Input('var_val') var_val;
  @Input('var_units') var_units;

  constructor() { 

  }

  ngOnInit(): void {
  }

}
