<div class="divdate">
    <mat-form-field appearance="fill">
        <mat-label>{{ 'SHARED.SELECTMONTH' | translate }}</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp
                        startView="multi-year"
                        (yearSelected)="chosenYearHandler($event)"
                        (monthSelected)="chosenMonthHandler($event, dp)"
                        panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>    
</div>

<div class="container-fluid">
    <div class="col-lg-9">
        <div class="row"><h3 style="margin: auto;">Тарифи ОСП та ПОН</h3></div>
        <app-pup
        [col1Name]=""
        [pupList]="ospList"
        [pupChange]="pupChange"
        ></app-pup>
    </div>
    <div class="col-lg-3">
    </div>
</div>    

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-9 divmap" >
            <app-chart
            (onNewArea) = "onNewArea($event)"
            ></app-chart>
        </div>
        <div class="col-lg-3 osrdiv">
            <div class="row"><h3 style="margin: auto;">Оператори мереж</h3></div>
            <app-osr
            [osrChange]="osrChange"
            [osrList]="osrList"
            (onOSR) = "onOSR($event)"
            ></app-osr>
        </div>
    </div>
</div>    
<div class="container-fluid">
    <div class="col-lg-12">
        <div class="row"><h3 style="margin: auto;">Постачальники універсальних послуг</h3></div>

        <app-pup
        [col1Name]="'Постачальники універсальних послуг'"
        [pupChange]="pupChange"
        [pupList]="pupList"
        ></app-pup>
    </div>
</div>    
