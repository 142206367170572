import { Component, OnInit } from '@angular/core';

import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-wholesale-market-installcapacity',
  templateUrl: './wholesale-market-installcapacity.component.html',
  styleUrls: ['./wholesale-market-installcapacity.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],     
})

export class WholesaleMarketInstallcapacityComponent implements OnInit {

  year: string
  month: string
  
  date = new FormControl(moment());
  constructor() { }

  ngOnInit(): void {

    am4core.useTheme(am4themes_animated);

    let chart1 = am4core.create("chartdivl", am4charts.PieChart);
    chart1.innerRadius = am4core.percent(40);

    chart1.data = [ {
      "country": "АЕС",
      "litres": 13835,
      "color": am4core.color("#5226CC")
    }, {
      "country": "ТЕС ГК",
      "litres": 21842,
      "color": am4core.color("#F0692F")
    }, {
      "country": "ТЕЦ",
      "litres": 6070,
      "color": am4core.color("#FF9F4B")
    }, {
      "country": "ГЕС",
      "litres": 4813,
      "color": am4core.color("#028AFF")
    }, {
      "country": "ГАЕС",
      "litres": 1488,
      "color": am4core.color("#0064BA")
    }, {
      "country": "СЕС",
      "litres": 5154,
      "color": am4core.color("#F4D220")
    }, {
      "country": "ВЕС",
      "litres": 1110,
      "color": am4core.color("#4ED1E3")
    }, {
      "country": "Біопаливо",
      "litres": 188,
      "color": am4core.color("#4EE3A4")
    }];
    
    let pieSeries1 = chart1.series.push(new am4charts.PieSeries());
    pieSeries1.dataFields.value = "litres";
    pieSeries1.dataFields.category = "country";
    pieSeries1.slices.template.propertyFields.fill = "color";
    pieSeries1.labels.template.fill = am4core.color("white");

    pieSeries1.slices.template.stroke = am4core.color("#fff");
    pieSeries1.slices.template.strokeWidth = 2;
    pieSeries1.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries1.hiddenState.properties.opacity = 1;
    pieSeries1.hiddenState.properties.endAngle = -90;
    pieSeries1.hiddenState.properties.startAngle = -90;


    let chart2 = am4core.create("chartdiv2", am4charts.PieChart);
    chart2.innerRadius = am4core.percent(40);

    chart2.data = [{
      "country": "СЕС",
      "litres": 5154,
      "color": am4core.color("#F4D220")
    }, {
      "country": "ВЕС",
      "litres": 1110,
      "color": am4core.color("#4ED1E3")
    }, {
      "country": "Біопаливо",
      "litres": 188,
      "color": am4core.color("#4EE3A4")
    }];
    
    let pieSeries2 = chart2.series.push(new am4charts.PieSeries());
    pieSeries2.dataFields.value = "litres";
    pieSeries2.dataFields.category = "country";
    pieSeries2.slices.template.propertyFields.fill = "color";
    pieSeries2.labels.template.fill = am4core.color("white");

    pieSeries2.slices.template.stroke = am4core.color("#fff");
    pieSeries2.slices.template.strokeWidth = 2;
    pieSeries2.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries1.hiddenState.properties.opacity = 1;
    pieSeries1.hiddenState.properties.endAngle = -90;
    pieSeries1.hiddenState.properties.startAngle = -90;


  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;

    let year = normalizedYear.year()
    this.year = year.toString()
    
    ctrlValue.year(year);
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.date.value;
    let month = normalizedMonth.month()
    this.month = (month + 1).toString()
    if (this.month.length < 2)
    {
      this.month = '0' + this.month
    }
    
    ctrlValue.month(month);
    this.date.setValue(ctrlValue);
    
//    this.mapComponent.dtPrice = '01/' + this.month + '/'+ this.year;
//    this.mapComponent.createRequest();
  }  


}
