<div class="ds_menu_container">
  <ul>
<!--    <div class ="ds_menu_title">ОПТОВИЙ РИНОК</div>-->
    <div class ="ds_menu_title">{{ 'MENU.WMARKET' | translate }}</div>
    <li class = "ds_menu_item"><a [routerLink]="" (click)="setPageRetailMapPrice()" class ="ds_menu_item_a">{{ 'MENU.MAPPRICE' | translate }}</a></li>
    <li class = "ds_menu_item"><a [routerLink]="" (click)="setPageWholePrice()" class ="ds_menu_item_a">{{ 'MENU.PRICES' | translate }}</a></li>
    <li class = "ds_menu_item"><a [routerLink]="" (click)="setPageWholeVolume()" class ="ds_menu_item_a">{{ 'MENU.VOLUMES' | translate }}</a></li>
    <li class = "ds_menu_item"><a [routerLink]="" (click)="setInstallCapacity()" class ="ds_menu_item_a">{{ 'MENU.INSTALLCAPACITY' | translate }}</a></li>
    <!--<li class = "ds_menu_item"><a [routerLink]="" (click)="setBlocksState()" class ="ds_menu_item_a">{{ 'MENU.BLOCKSSTATE' | translate }}</a></li>-->
    <li class = "ds_menu_item"><a [routerLink]="" (click)="setManufacturers()" class ="ds_menu_item_a">{{ 'MENU.MANUFACTURERS' | translate }}</a></li>
    <li class = "ds_menu_item"><a [routerLink]="" (click)="setTablets()" class ="ds_menu_item_a">{{ 'MENU.TABLETS' | translate }}</a></li>
    <div class ="ds_menu_title">{{ 'MENU.RMARKET' | translate }}</div>
    <li class = "ds_menu_item"><a [routerLink]="" (click)="setPageRetailGeneralInfo()" class ="ds_menu_item_a">{{ 'MENU.GENERALINFO' | translate }}</a></li>
    <div class ="ds_menu_title">{{ 'MENU.FORECAST' | translate }}</div>
    <li class = "ds_menu_item"><a [routerLink]="" (click)="setPageForecastRDN()" class ="ds_menu_item_a">{{ 'MENU.RDN' | translate }}</a></li>
    <li class = "ds_menu_item"><a [routerLink]="" (click)="setDAMDemand()" class ="ds_menu_item_a">{{ 'MENU.RDN_DEMAND' | translate }}</a></li>
    <li class = "ds_menu_item"><a [routerLink]="" (click)="setPageForecastRDN()" class ="ds_menu_item_a">{{ 'MENU.RZDB' | translate }}</a></li>
    <div class ="ds_menu_title">{{ 'MENU.VDE' | translate }}</div>
    <li class = "ds_menu_item"><a [routerLink]="" (click)="setVDEGeneral()" class ="ds_menu_item_a">{{ 'MENU.VDE_GEN' | translate }}</a></li>
  </ul>
</div>