<div class="common-button">
    <div class="text" (click)="setPageWholePrice()">ВХІД</div>
</div>
<div class="row fullpic">
    <div class="col-lg-12 para-1">
        <span>Equant Cloud</span>
    </div>
    <div class="col-lg-12 para-2">
        <span>{{ 'MAINPAGE.STR0' | translate }}</span>
    </div>
</div>
<div class="row">
    <div class="col-lg-6 para-2">
        <span class="bold">Equant Cloud</span>
        <span>{{ 'MAINPAGE.STR1' | translate }}</span>
    </div>
    <div class="col-lg-6">
        <div class=" sotpic">
            <img class="sot2" src="../../../assets/image/sot2.png">
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <div class=" monbgpic">
            <img class="mon1" src="../../../assets/image/mon1.png">
        </div>
    </div>
    <div class="col-lg-6 mon2 para-2">
        <div class="row">
            <span>{{ 'MAINPAGE.STR2' | translate }}</span>
            <span>{{ 'MAINPAGE.STR3' | translate }}</span>
            <br><br><br><br>
            <span>Для отримання доступу до тестового програмного комплексу надайте наступну інформацію: 
                ПІБ користувача, електронна адреса, телефон для спілкування та назва підприємства.
                Зазначену інформацію направляти на вказану на сайті електронну адресу. 
                Логін та пароль доступу до системи буде направлена на вказану у листі електронну адресу.</span>
                
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-10 para-2">
        <br> <br> <br>
        <div class="row">
            <span>Пропонується 3 пакета послуг, які залежать від наповненості інтерфейсу
            та обробки даних, що використовується при здійсненні діяльності із
            купівлею та продажам електричної енергії, а саме:</span>
        </div>
        <div class="row">
            <span>- EQUANT. Standard – Інтерфейс необхідної статистичної, динамічної
            та візуалізовано інформації по обсягах, цінах та тарифах на
            відповідних сегментах (оптовий та роздрібний) ринках електричної
            енергії України;</span>
        </div>
        <div class="row">
        <span>- EQUANT. Green – Додатково до пакету Standard доповнений
            інтерфейс необхідної інформації щодо діяльності відновлювальних
            джерел енергії в Україні, в розрізі регіонів (в розробці);</span>
        </div>
        <div class="row">
            <span>- EQUANT. Pro – Додатково до пакетів Standard та Green доповнений
            інтерфейс проведення прогнозування цін на ринку на добу наперед
            та двосторонніх договорах (в розробці), а також здійснення різних
            сценарних розрахунків цін на роздрібному ринку, в розрізі регіонів
            (в розробці).</span>
            </div>
        </div>
    </div>
<div class="row">
    <div class="col-lg-4 para-3">
        <div class="row"><span>{{ 'MAINPAGE.STR4' | translate }}</span></div>
        <div class="row"><span>Тел. +38 (050) 884-83-83 </span></div>
        <div class="row"><span>Тел. +38 (067) 884-83-83 </span></div>
        <div class="row"><span>Email:  equant@ipme.kiev.ua </span></div>
    </div>
    <div class="col-lg-4">
        <div class="infopic">
        </div>
    </div>
</div>

<!--
<ul>
<li class = "ds_menu_item"><a [routerLink]="" (click)="setPageWholePrice()" class ="ds_menu_item_a">{{ 'MENU.PRICES' | translate }}</a></li>
</ul>

<p>maininfo works!</p>
-->