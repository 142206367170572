<div class="divdate">
    <mat-form-field appearance="fill">
        <mat-label>{{ 'SHARED.SELECTMONTH' | translate }}</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp
                        startView="multi-year"
                        (yearSelected)="chosenYearHandler($event)"
                        (monthSelected)="chosenMonthHandler($event, dp)"
                        panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>    
</div>

<div class="row"><h3 style="margin: auto;">Структура виробництва і споживання е/е</h3></div>

<div class="row map">
  <div class="col-lg-6 divmap" >
    <h2 class="card-title">Виробіток</h2>
    <div id="chartdiv1" style="width: 100%; height: 300px"></div>
  </div>
  <div class="col-lg-6 divmap" >
    <h2 class="card-title">Споживання</h2>
    <div id="chartdiv2" style="width: 100%; height: 300px"></div>
  </div>
</div>
