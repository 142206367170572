<mat-toolbar class="ds-toolbar">
    <app-toolbar
      [menu_name] = "menu_name"
      [submenu_name] = "submenu_name"
    ></app-toolbar>
</mat-toolbar>

<div *ngIf="readLocalStorageValue('JWT_TOKEN') == 'true'; else elseBlock">

  <mat-sidenav-container class="example-container">
      <mat-sidenav #sidenav mode="side" opened class="example-sidenav"
                  [fixedInViewport]=false 
                  [fixedTopGap]=true
                  [fixedBottomGap]=false
      >

      <app-menu
        (onNewMenuItem)=onNewMenuItem($event)
      ></app-menu>

      </mat-sidenav>
      <mat-sidenav-content class="ds-content">

          <router-outlet></router-outlet>

      </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<ng-template #elseBlock>

  <router-outlet></router-outlet>

</ng-template>

