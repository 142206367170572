<div class="container">

    <app-download-exel *ngIf="data_codeExcel > 0"
        [date_start] = "date_s"
        [date_end] = "date_e"
        [data_code] = "data_codeExcel"
    ></app-download-exel>
    <div class="var-desc">
        {{ var_desc | translate }}
    </div>
</div>
