import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DateAdapter} from '@angular/material/core';
import { DatePipe } from '@angular/common';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as moment from 'moment';

import {
  API_FORECAST_RDN
} from '../../api-endpoints';

import {API_WHOLESALE_MARKET_PRICES_DOWNLOAD_AS_EXEL} from '../../api-endpoints';


export interface ValList{
  hour: number
  val: string
  valproc: string
}


@Component({
  selector: 'app-forecast-rdn',
  templateUrl: './forecast-rdn.component.html',
  styleUrls: ['./forecast-rdn.component.css']
})


export class ForecastRdnComponent implements OnInit {

  chart: any
  dataChart: any = [];

  date: any
  date_start: any
  date_end: any
  data_code: any


  series1: any
  series2: any
  series3: any
  series4: any

  public graphVals: ValList[] = []

  file_name: string;
  file_type: string = '.txt';



  constructor(private http: HttpClient, private _adapter: DateAdapter<any>, public datepipe: DatePipe) { }

  performDownload(): void {
    console.log(this.date_start)
    console.log(this.date_end)
    this.http.get<any>(API_WHOLESALE_MARKET_PRICES_DOWNLOAD_AS_EXEL, {
      responseType: 'blob' as 'json',
      params: {
        date_start: this.date_start,
        date_end: this.date_end,
        data_code: this.data_code
      }
    }).subscribe(response => this.saveFile(response))
  }

  saveFile(response) {

    const ds_arr = this.date_start.split('/')
    const de_arr = this.date_end.split('/')
    console.log(ds_arr)
    this.file_name = 'report_' + this.data_code + '_' +  ds_arr[2] +  ds_arr[1] +  ds_arr[0] + '-' + de_arr[2] +  de_arr[1] +  de_arr[0] + '.xlsx'

    const downloadURL = window.URL.createObjectURL(response);
    const link = document.createElement('a');

    link.href = downloadURL;
    link.download = this.file_name;
    link.click();
  }


  ngOnInit(): void {
    this.data_code = 999
    setTimeout(() => {
    am4core.useTheme(am4themes_animated);
    this.chart = am4core.create("chartdiv", am4charts.XYChart);
    this.chart.colors.list = [
      am4core.color("#3333FF"),
      am4core.color("#00AA00"),
      am4core.color("#FF00FF"),
      am4core.color("#FFFFFF")
    ];
    this.chart.data = this.dataChart;
    this.chart.legend = new am4charts.Legend();
    this.chart.legend.labels.template.fill = am4core.color("#FFFFFF");

    // Create axes
    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());

    dateAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    dateAxis.renderer.grid.template.stroke = am4core.color("#FFFFFF");

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    valueAxis.renderer.grid.template.stroke = am4core.color("#FFFFFF");

    // Create series
    this.series1 = this.chart.series.push(new am4charts.LineSeries());
    this.series1.dataFields.valueY = "value1";
    this.series1.dataFields.dateX = "date";
    this.series1.tooltipText = "{value1}"
    this.series1.name = ""
    this.series1.tooltip.pointerOrientation = "vertical";
    this.series1.strokeWidth = 2;
    this.series1.strokeDasharray = "2,2";
  

    this.series2 = this.chart.series.push(new am4charts.LineSeries());
    this.series2.dataFields.valueY = "value2";
    this.series2.tooltipText = "{value2}"
    this.series2.dataFields.dateX = "date";
    this.series2.name = ""
    this.series2.tooltip.pointerOrientation = "vertical";
    this.series2.strokeWidth = 2;
    this.series2.strokeDasharray = "2,2";

    this.series3 = this.chart.series.push(new am4charts.LineSeries());
    this.series3.dataFields.valueY = "value3";
    this.series3.tooltipText = "{value3}"
    this.series3.dataFields.dateX = "date";
    this.series3.name = ""
    this.series3.tooltip.pointerOrientation = "vertical";
    this.series3.strokeWidth = 3;

    this.series4 = this.chart.series.push(new am4charts.LineSeries());
    this.series4.dataFields.valueY = "value4";
    this.series4.tooltipText = "{value4}"
    this.series4.dataFields.dateX = "date";
    this.series4.name = ""
    this.series4.tooltip.pointerOrientation = "vertical";
    this.series4.strokeWidth = 3;


    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.snapToSeries = [this.series1, this.series2, this.series3, this.series4];
    this.chart.cursor.xAxis = dateAxis;

    

    //chart.scrollbarY = new am4core.Scrollbar();
    //this.chart.scrollbarX = new am4core.Scrollbar();
/*
    var data = [];
    var value1 = 50;
    var value2 = 50;
    var value3 = 50;
    var value4 = 50;
    for(var i = 0; i < 24; i++){
      var date = new Date();
      date.setHours(i,0,0,0);
//      date.setDate(i);
      value1 -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      value2 -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      value3 -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      value4 -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      data.push({date:date, value1: value1, value2: value2, value3: value3, value4: value4});
    }

    this.chart.data = data;
  */  
    }, 0);
    this._adapter.setLocale('ukr');
  }

  updateDate(event){
    this.date = this.datepipe.transform(event.value, 'dd/MM/yyyy')
    this.date_start = this.date
    this.date_end = this.date
    this.createRequest()
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  createRequest(){
    console.log('request')
    this.http.get<any>(API_FORECAST_RDN, {
      params:{
        dt: this.date
      },
      observe: 'response'}).subscribe(response => {
//      this.data = response.body;
      console.log(response.body)
      let dat = JSON.parse(JSON.stringify(response.body))
      let dataChart = []
      this.graphVals = []
      for (let i = 0; i < dat.length; i++){
        let date = new Date(dat[i].date); 
        let valproc = ((dat[i].value4 - dat[i].value3)/dat[i].value4 * 100).toFixed(1);
        if (parseInt(valproc) >= 100 || valproc == 'NaN'){
          valproc = ''
        }
        else{
          valproc += ' %'
        }

        dataChart.push({date:date, value1: dat[i].value1, value2: dat[i].value2, value3: dat[i].value3, value4: dat[i].value4});
        this.graphVals.push({hour:i+1, val:dat[i].value4, valproc: valproc});
      }

      console.log(this.graphVals)
      
      var dateMomentObject = moment(this.date, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
      let date1 = this.addDays(dateObject, -2)
      let date2 = this.addDays(dateObject, -1)
      let date3 = dateObject

      this.chart.data = dataChart;
      this.series1.name = "факт на " + moment(date1).format("DD/MM/YYYY")
      this.series2.name = "факт на " + moment(date2).format("DD/MM/YYYY")
      this.series3.name = "факт на " + moment(date3).format("DD/MM/YYYY")
      this.series4.name = "прогноз на " + moment(date3).format("DD/MM/YYYY")
//      console.log(dataChart.length)
      return response.body
    });
  }


}
