<div class="container">
    <!--
    <div class="var-desc">{{ var_desc }}</div>
    <div class="var-val">{{ var_val }}</div>
    <div class="var-units">{{ var_units }}</div>
    -->
    <div class="row">
        <div class="col-md-5 desc">
            {{ var_desc | translate}}
        </div>
        <div class="col-md-5 units">
            {{ var_units | translate}}
        </div>
    </div>
    <div class="var-val">{{ var_val }}</div>
</div>
