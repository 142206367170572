import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as myURL from '../../api-endpoints';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {

  @Output() onNewMenuItem = new EventEmitter<any>();

  constructor(private router: Router, public translate: TranslateService) { }

  ngOnInit(): void {
  }

  getPageName(inURL : string): string {
    let retURL = inURL;
    let lInd = inURL.lastIndexOf('/');
    if (lInd + 1 == inURL.length){
      retURL = inURL.substring(0, lInd);
    }

    lInd = retURL.lastIndexOf('/');
    if (lInd > 0){
      retURL = retURL.substring(lInd);
    }
    return retURL;
  }

  setPageWholePrice(){
    var arr_menu = []
/*    this.translate.get('MENU.WMARKET').subscribe(val=>{
      arr_menu.push(val)
    })*/
    arr_menu.push('MENU.WMARKET')  
    arr_menu.push('MENU.PRICES')
    this.onNewMenuItem.emit(arr_menu)
    this.router.navigate([this.getPageName(myURL.API_WHOLESALE_MARKET_PRICES)]);
  }
  setPageWholeVolume(){
    var arr_menu = []
    arr_menu.push('MENU.WMARKET')
    arr_menu.push('MENU.VOLUMES')
    this.onNewMenuItem.emit(arr_menu)
    this.router.navigate([this.getPageName(myURL.API_WHOLESALE_MARKET_VOLUMES)]);
  }
  
  setPageRetailGeneralInfo()
  {
    var arr_menu = []
    arr_menu.push('MENU.RMARKET')
    arr_menu.push('MENU.GENERALINFO')
    this.onNewMenuItem.emit(arr_menu)
    this.router.navigate([this.getPageName(myURL.API_RETAIL_MARKET_GENERALINFO)]);
  }

  setPageRetailMapPrice()
  {
    var arr_menu = []
    arr_menu.push('MENU.WMARKET')
    arr_menu.push('MENU.MAPPRICE')
    this.onNewMenuItem.emit(arr_menu)
    this.router.navigate([this.getPageName(myURL.API_RETAIL_MARKET_MAPPRICE)]);
  }

  setInstallCapacity()
  {
    var arr_menu = []
    arr_menu.push('MENU.WMARKET')
    arr_menu.push('MENU.INSTALLCAPACITY')
    this.onNewMenuItem.emit(arr_menu)
    this.router.navigate([this.getPageName(myURL.API_WHOLESALE_MARKET_INSTALLCAPACITY)]);
  }  

  setBlocksState()
  {
    var arr_menu = []
    arr_menu.push('MENU.WMARKET')
    arr_menu.push('MENU.BLOCKSSTATE')
    this.onNewMenuItem.emit(arr_menu)
    this.router.navigate([this.getPageName(myURL.API_WHOLESALE_MARKET_BLOCKS)]);
  }  

  setPageForecastRDN()
  {
    var arr_menu = []
    arr_menu.push('MENU.FORECAST')
    arr_menu.push('MENU.RDN')
    this.onNewMenuItem.emit(arr_menu)
    this.router.navigate([this.getPageName(myURL.API_FORECAST_RDN)]);
  }  

  setManufacturers()
  {
    var arr_menu = []
    arr_menu.push('MENU.WMARKET')
    arr_menu.push('MENU.MANUFACTURERS')
    this.onNewMenuItem.emit(arr_menu)
    this.router.navigate([this.getPageName(myURL.API_MANUFACTURERS)]);
  }  

  setTablets()
  {
    var arr_menu = []
    arr_menu.push('MENU.WMARKET')
    arr_menu.push('MENU.TABLETS')
    this.onNewMenuItem.emit(arr_menu)
    this.router.navigate([this.getPageName(myURL.API_TABLETS)]);
  }  

  setVDEGeneral()
  {
    var arr_menu = []
    arr_menu.push('MENU.VDE')
    arr_menu.push('MENU.VDE_GEN')
    this.onNewMenuItem.emit(arr_menu)
    //this.router.navigate([this.getPageName(myURL.API_TABLETS)]);
    this.router.navigate(["/vde-general"]);
  }
  
  setDAMDemand()
  {
    var arr_menu = []
    arr_menu.push('MENU.VDE')
    arr_menu.push('MENU.VDE_GEN')
    this.onNewMenuItem.emit(arr_menu)
    //this.router.navigate([this.getPageName(myURL.API_TABLETS)]);
    this.router.navigate(["/dam-demand"]);
  }

}
