<div class="body_auth">


<div class="signin_alert">
  <div class="signin_content">
    <div class="signin_title">Авторизація</div>
    <form [formGroup]="loginForm" (ngSubmit)="login()">

      <div class="signin_email">
        Ім’я користувача
        <label>
          <input  type="text" class="signin_email_input" id="username" placeholder="Username" autocomplete="off" formControlName="username" required>
        </label>
      </div>

      <div class="signin_password">
        ПАРОЛЬ
        <label>
          <input  type="password" class="signin_password_input" id="password" placeholder="Password" formControlName="password" required>
        </label>
      </div>

      <div class="actions signin_buttoncontainer">
        <button type="submit" class="btn btn-default signin_submit" [disabled]="!loginForm.valid">&nbsp;&nbsp;Авторизація&nbsp;&nbsp;</button>
      </div>

      <div class="form-group signin_buttoncontainer">
        <button type="submit" class="btn btn-default signin_submit" [disabled]="!loginForm.valid">&nbsp;&nbsp;Реєстрація&nbsp;&nbsp;</button>
      </div>      

    </form>
  </div>
</div>

</div>

