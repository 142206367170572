import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-marketblockrdd',
  templateUrl: './marketblockrdd.component.html',
  styleUrls: ['./marketblockrdd.component.css']
})
export class MarketblockrddComponent implements OnInit {
  @Input('date_s') date_s;
  @Input('date_e') date_e;
  @Input('data_codeExcel') data_codeExcel;

  @Input('market_name') market_name;

  @Input('area1_name') area1_name;

  @Input('var_desc11') var_desc11;
  @Input('var_val11') var_val11;
  @Input('var_units11') var_units11;

  @Input('var_desc12') var_desc12;
  @Input('var_val12') var_val12;
  @Input('var_units12') var_units12;

  @Input('var_desc13') var_desc13;
  @Input('var_val13') var_val13;
  @Input('var_units13') var_units13;

  @Input('var_desc_b1_1_7') var_desc_b1_1_7;
  @Input('var_val_b1_1_7') var_val_b1_1_7;
  @Input('var_units_b1_1_7') var_units_b1_1_7;

  @Input('var_desc_b1_8_22') var_desc_b1_8_22;
  @Input('var_val_b1_8_22') var_val_b1_8_22;
  @Input('var_units_b1_8_22') var_units_b1_8_22;

  @Input('var_desc_b1_23_24') var_desc_b1_23_24;
  @Input('var_val_b1_23_24') var_val_b1_23_24;
  @Input('var_units_b1_23_24') var_units_b1_23_24;
  
  @Input('area2_name') area2_name;
 
  @Input('var_desc21') var_desc21;
  @Input('var_val21') var_val21;
  @Input('var_units21') var_units21;

  @Input('var_desc22') var_desc22;
  @Input('var_val22') var_val22;
  @Input('var_units22') var_units22;

  @Input('var_desc23') var_desc23;
  @Input('var_val23') var_val23;
  @Input('var_units23') var_units23;

  @Input('var_desc_b2_1_7') var_desc_b2_1_7;
  @Input('var_val_b2_1_7') var_val_b2_1_7;
  @Input('var_units_b2_1_7') var_units_b2_1_7;

  @Input('var_desc_b2_8_22') var_desc_b2_8_22;
  @Input('var_val_b2_8_22') var_val_b2_8_22;
  @Input('var_units_b2_8_22') var_units_b2_8_22;

  @Input('var_desc_b2_23_24') var_desc_b2_23_24;
  @Input('var_val_b2_23_24') var_val_b2_23_24;
  @Input('var_units_b2_23_24') var_units_b2_23_24;

  constructor() { }

  ngOnInit(): void {
  }

}
