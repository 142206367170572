import { Component, OnInit } from '@angular/core';

import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'vde-general',
  templateUrl: './vde-general.component.html',
  styleUrls: ['./vde-general.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],     
})

export class VDEGeneralComponent implements OnInit {

  year: string
  month: string
  
  date = new FormControl(moment());
  constructor() { }

  ngOnInit(): void {

    am4core.useTheme(am4themes_animated);

    let chart1 = am4core.create("chartdivl", am4charts.PieChart);
    chart1.innerRadius = am4core.percent(40);

    chart1.data = [ {
      "country": "ВЕС",
      "litres": 3866,
      "color": am4core.color("#0bbaef")
    }, {
      "country": "СЕС (вклюсно з дСЕС)",
      "litres": 7670,
      "color": am4core.color("#fed501")
    }, {
      "country": "мГЕС",
      "litres": 276,
      "color": am4core.color("#1973bb")
    }, {
      "country": "Біогаз/біомаса",
      "litres": 992,
      "color": am4core.color("#94c11e")
    }];
    
    let pieSeries1 = chart1.series.push(new am4charts.PieSeries());
    pieSeries1.dataFields.value = "litres";
    pieSeries1.dataFields.category = "country";
    pieSeries1.slices.template.propertyFields.fill = "color";
    pieSeries1.labels.template.fill = am4core.color("white");

    pieSeries1.slices.template.stroke = am4core.color("#fff");
    pieSeries1.slices.template.strokeWidth = 2;
    pieSeries1.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries1.hiddenState.properties.opacity = 1;
    pieSeries1.hiddenState.properties.endAngle = -90;
    pieSeries1.hiddenState.properties.startAngle = -90;

    let chart3 = am4core.create("chartdiv3", am4charts.PieChart);
    chart3.innerRadius = am4core.percent(40);

    chart3.data = [ {
      "country": "ВЕС",
      "litres": 3866,
      "color": am4core.color("#0bbaef")
    }, {
      "country": "СЕС (вклюсно з дСЕС)",
      "litres": 7670,
      "color": am4core.color("#fed501")
    }, {
      "country": "мГЕС",
      "litres": 276,
      "color": am4core.color("#1973bb")
    }, {
      "country": "Біогаз/біомаса",
      "litres": 992,
      "color": am4core.color("#94c11e")
    }];
    
    let pieSeries3 = chart3.series.push(new am4charts.PieSeries());
    pieSeries3.dataFields.value = "litres";
    pieSeries3.dataFields.category = "country";
    pieSeries3.slices.template.propertyFields.fill = "color";
    pieSeries3.labels.template.fill = am4core.color("white");

    pieSeries3.slices.template.stroke = am4core.color("#fff");
    pieSeries3.slices.template.strokeWidth = 2;
    pieSeries3.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries3.hiddenState.properties.opacity = 1;
    pieSeries3.hiddenState.properties.endAngle = -90;
    pieSeries3.hiddenState.properties.startAngle = -90;


    let chart2 = am4core.create("chartdiv2", am4charts.PieChart);
    chart2.innerRadius = am4core.percent(40);

    chart2.data = [ {
      "country": "ВЕС",
      "litres": 3866,
      "color": am4core.color("#0bbaef")
    }, {
      "country": "СЕС (вклюсно з дСЕС)",
      "litres": 7670,
      "color": am4core.color("#fed501")
    }, {
      "country": "мГЕС",
      "litres": 276,
      "color": am4core.color("#1973bb")
    }, {
      "country": "Біогаз/біомаса",
      "litres": 992,
      "color": am4core.color("#94c11e")
    }];
    
    let pieSeries2 = chart2.series.push(new am4charts.PieSeries());
    pieSeries2.dataFields.value = "litres";
    pieSeries2.dataFields.category = "country";
    pieSeries2.slices.template.propertyFields.fill = "color";
    pieSeries2.labels.template.fill = am4core.color("white");

    pieSeries2.slices.template.stroke = am4core.color("#fff");
    pieSeries2.slices.template.strokeWidth = 2;
    pieSeries2.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries1.hiddenState.properties.opacity = 1;
    pieSeries1.hiddenState.properties.endAngle = -90;
    pieSeries1.hiddenState.properties.startAngle = -90;

    //legend
    chart1.legend = new am4charts.Legend();

    /* Create a separate container to put legend in */
    var legendContainer = am4core.create("clegend", am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    chart1.legend.parent = legendContainer;
    chart1.legend.labels.template.fill = am4core.color("#fff");
    chart1.legend.valueLabels.template.fill = am4core.color("#fff"); 

    pieSeries1.labels.template.disabled = true;
    pieSeries1.ticks.template.disabled = true;
    //
    chart2.legend = new am4charts.Legend();
    var legendContainer2 = am4core.create("clegend2", am4core.Container);
    legendContainer2.width = am4core.percent(100);
    legendContainer2.height = am4core.percent(100);
    chart2.legend.parent = legendContainer2;
    chart2.legend.labels.template.fill = am4core.color("#fff");
    chart2.legend.valueLabels.template.fill = am4core.color("#fff"); 

    pieSeries2.labels.template.disabled = true;
    pieSeries2.ticks.template.disabled = true;
    //
    chart3.legend = new am4charts.Legend();
    var legendContainer3 = am4core.create("clegend3", am4core.Container);
    legendContainer3.width = am4core.percent(100);
    legendContainer3.height = am4core.percent(100);
    chart3.legend.parent = legendContainer3;
    chart3.legend.labels.template.fill = am4core.color("#fff");
    chart3.legend.valueLabels.template.fill = am4core.color("#fff"); 

    pieSeries3.labels.template.disabled = true;
    pieSeries3.ticks.template.disabled = true;


  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;

    let year = normalizedYear.year()
    this.year = year.toString()
    
    ctrlValue.year(year);
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.date.value;
    let month = normalizedMonth.month()
    this.month = (month + 1).toString()
    if (this.month.length < 2)
    {
      this.month = '0' + this.month
    }
    
    ctrlValue.month(month);
    this.date.setValue(ctrlValue);
    
//    this.mapComponent.dtPrice = '01/' + this.month + '/'+ this.year;
//    this.mapComponent.createRequest();
  }  


}
