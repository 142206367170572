<div class="divdate">
    <mat-form-field appearance="fill">
        <mat-label>{{ 'SHARED.SELECTPERIOD' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker" (dateChange)="addNewDate($event)" >
          <input matStartDate placeholder="Start date" (dateChange)="setStart($event)" [value]="dateS.value">
          <input matEndDate placeholder="End date" (dateChange)="setEnd($event)" [value]="dateE.value">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
</div>

<div class="row"><h3 style="margin: auto;">{{ 'HEADERS.MANUFACTURERS' | translate }}</h3></div>

<span class="sp-gen">Типи генерації:</span>
<select class='select-option'
    #mySelect
    (change)='onOptionsSelected(mySelect.value)'>
    <option class='option' 
    *ngFor='let option of comboTypeData' 
    [value]="option.value">{{option.label}}</option>
</select>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 divmap" >
            <div id="chartdiv" style="width: 100%; height: 600px"></div>
        </div>
        <div class="col-lg-6 osrdiv">
            <div class="row rowstation">
                <p class="blocktext">{{ StationName }}</p>
            </div>
            <div class="row rowstation" *ngIf="StationVal!==undefined">
                <p class="blocktext"> Поточна генерація: {{ StationVal }} МВт</p>
            </div>
            <div id="graphdiv" style="width: 100%; height: 600px"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3 divmap" >
            <div *ngFor="let block of blockList">
                <div class="row block_row" >
                    <div class="col-lg-8">
                        <span class="pseudolink"  (click)="blockClick(block.id)">{{block.name}}</span>
                    </div>
                    <div class="col-lg-4">
                        <span>{{block.val}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9" >
            <div class="row">
                {{ 'SHARED.ZG' | translate }}
            </div>
            <div class="row">
                <span>АЕС</span><span class="zi">{{AESval}}</span>
            </div>
            <div class="row">
                <span>ТЕС</span><span class="zi">{{TESval}}</span>
            </div>
            <div class="row">
                <span>ТЕЦ</span><span class="zi">{{TECval}}</span>
            </div>
            <a class="pseudolink" (click)="goToLink('http://mpe.kmu.gov.ua/minugol/doccatalog/document?id=245487347')"><h4>Графік виконання ремонту основного обладнання на 2021 рік</h4></a>
            <a class="pseudolink" (click)="goToLink('http://mpe.kmu.gov.ua/minugol/doccatalog/document?id=245530053')"><h4>Прогнозний баланс електроенерії ОЕС України на 2021 рік</h4></a>
            <a class="pseudolink" (click)="goToLink('http://mpe.kmu.gov.ua/minugol/doccatalog/document?id=245582198')"><h4>Прогнозний баланс електроенерії ОЕС України на 2021 рік (оновлений)</h4></a>            
        </div>
    </div>
</div>    

