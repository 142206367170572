import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import {
  API_RETAIL_MARKET_GENERALINFO
} from '../../api-endpoints';

import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';

import { MapEuropeComponent } from 'src/app/map-europe/map-europe.component';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

//import { DatePipe } from '@angular/common';
//import {DateAdapter} from '@angular/material/core';


@Component({
  selector: 'app-retail-market-mapprice',
  templateUrl: './retail-market-mapprice.component.html',
  styleUrls: ['./retail-market-mapprice.component.css'],
providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],  

})
export class RetailMarketMappriceComponent implements AfterViewInit {

  @ViewChild(MapEuropeComponent)
  mapComponent : MapEuropeComponent;

  year: string
  month: string
  
  date = new FormControl(moment());

  constructor() { }

    chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;

    let year = normalizedYear.year()
    this.year = year.toString()
    
    ctrlValue.year(year);
    this.date.setValue(ctrlValue);
  }

  ngAfterViewInit(): void {
    const year = new Date().getFullYear()
    this.year = year.toString()
    const month = (new Date().getMonth()) + 1;
    this.month = month.toString()
    this.mapComponent.marketType = 1;
    this.mapComponent.dtPrice = '01/' + this.month + '/'+ this.year;
    this.mapComponent.createRequest();
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.date.value;
    let month = normalizedMonth.month()
    this.month = (month + 1).toString()
    if (this.month.length < 2)
    {
      this.month = '0' + this.month
    }
    
    ctrlValue.month(month);
    this.date.setValue(ctrlValue);
    
    this.mapComponent.dtPrice = '01/' + this.month + '/'+ this.year;
    this.mapComponent.createRequest();
  }  

}
