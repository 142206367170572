import { Component, Input, OnInit, AfterViewInit, OnDestroy, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4geodata_UAHigh from "@amcharts/amcharts4-geodata/ukraineHigh";
import am4geodata_EuropeHigh from "@amcharts/amcharts4-geodata/region/world/europeHigh";
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import {HttpClient} from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import {
  API_RETAIL_MARKET_MAPPRICE
} from '../api-endpoints';
import { MENU_PANEL_TOP_PADDING } from '@angular/material/menu/menu-trigger';


@Component({
  selector: 'app-map-europe',
  templateUrl: './map-europe.component.html',
  styleUrls: ['./map-europe.component.css'],

})

export class MapEuropeComponent implements AfterViewInit {

  private chartEurope: am4maps.MapChart;

  priceUA:any

  imageSeriesData:any

  data: any;
  marketType: any;

  dtPrice: string;

  tooltipData:any;
  polygonSeriesMy: any;

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId, private zone: NgZone, public translate: TranslateService) {  }

   // Run the function only in the browser
   browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit() {

    setTimeout(() => {
    // Chart code goes in here
    this.browserOnly(() => {

      console.log("ngAfterViewInit()")
      am4core.useTheme(am4themes_animated);

      am4core.options.autoDispose = true;
      
      let chart = am4core.create("chartdiv", am4maps.MapChart);
      chart.geodata = am4geodata_EuropeHigh;
      chart.homeZoomLevel = 1.8;
      chart.homeGeoPoint = {
        latitude: 52,
        longitude: 20
      };

      chart.projection = new am4maps.projections.Miller();

      this.polygonSeriesMy = chart.series.push(new am4maps.MapPolygonSeries())
      this.polygonSeriesMy.exclude = ["RU", "SJ", "IS", "TR", "GE", "AM", "AZ"]
      this.polygonSeriesMy.useGeodata = true


      let polygonTemplate = this.polygonSeriesMy.mapPolygons.template;
      polygonTemplate.polygon.fillOpacity = 0.6;
      polygonTemplate.fill = am4core.color("#3a414c");
      polygonTemplate.stroke = am4core.color("#656b72");

      let hs = polygonTemplate.states.create("hover");
      hs.properties.fill = am4core.color("#da2060");
      this.polygonSeriesMy.calculateVisualCenter = true;

      //this.createSeries()
      polygonTemplate.tooltipText = "{title}";
      polygonTemplate.tooltipPosition = "fixed";      
      polygonTemplate.showTooltipOn = "always";
      this.chartEurope = chart;



      this.marketType = '1'
      this.dtPrice = '01/12/2020'

      //this.createRequest()
      });}, 0);
  }

  createSeries(data){

    console.log("data=", data)
    this.polygonSeriesMy.data = []
    let array = []

    if (Object.keys(data).length === 0)
    {
      array.push({
        "id": "AU",
        "title": ""
      })
      this.polygonSeriesMy.data = array;
      return;
    }

    var mapping = {
      '1001': {
        "id": "FI",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.FI') + "\n"
      },
      '1002': {
        "id": "EE",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.EE') + "\n"
      },
      '1003': {
        "id": "LV",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.LV') + "\n"
      },
      '1004': {
        "id": "LT",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.LT') + "\n"
      },
      '1005': {
        "id": "PL",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.PL') + "\n"
      },
      '1006': {
        "id": "SK",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.SK') + "\n"
      },
      '1007': {
        "id": "RO",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.RO') + "\n"
      },
      '1008': {
        "id": "HU",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.HU') + "\n"
      },
      '1009': {
        "id": "CZ",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.CZ') + "\n"
      },
      '1010': {
        "id": "AT",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.AT') + "\n"
      },
      '1011': {
        "id": "NL",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.NL') + "\n"
      },
      '1012': {
        "id": "BE",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.BE') + "\n"
      },
      '1013': {
        "id": "FR",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.FR') + "\n"
      },
      '1014': {
        "id": "UA",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.UA') + "\n"
      },
      '1015': {
        "id": "MD",
        "title": "[font-size:12]" + this.translate.instant('COUNTRY.MD') + "\n"
      }
    }

    for (var key in data)
    {
      let curObject = mapping[key.toString()]
      curObject['title'] += data[key].toString()
      array.push(curObject)
    }

    this.polygonSeriesMy.data = array

    console.log(this.polygonSeriesMy.data)
  }

  getValue(inVal: any): string {
    if (inVal === undefined)
    {
      return '-'
    }

    var strVal = inVal.toString();
    if (strVal.length > 1)
    {
      var fVal = parseFloat(strVal);
      strVal = fVal.toFixed(2);
      strVal = strVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return strVal;
  }


  createRequest(): void {
    console.log("createRequest()")

    if (this.dtPrice.length < 6)
      return;

    this.http.get<any>(API_RETAIL_MARKET_MAPPRICE, {
      params:{
        date: this.dtPrice,
        marketType: this.marketType
      },
      observe: 'response'}).subscribe(response => {
      this.data = response.body;

      console.log('>>>>>>>>>', this.data);

      this.priceUA = 42
      this.createSeries(this.data)
      
    });
    
  }



/*
  ngOnDestroy() {
    
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      
      am4core.disposeAllCharts();
      if (this.chartEurope) {
        console.log('ngOnDestroy start...')
        console.log(this.chartEurope)
        console.log('dispose...')
        let ret = this.chartEurope.dispose();
        console.log(ret)
        console.log(this.chartEurope)
        delete this.chartEurope
        console.log(this.chartEurope)
        console.log('ngOnDestroy end')
      }

    });
  }
*/
 
}
