import { Component, OnInit, Input } from '@angular/core';
import { TranslateCompiler, TranslateService } from '@ngx-translate/core';
import {AuthService} from '../../auth/services/auth.service';


interface Lang {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  langSelect:string

  @Input('menu_name') menu_name;
  @Input('submenu_name') submenu_name;


  constructor(public auth: AuthService, public translate: TranslateService) { }

  ngOnInit(): void {
    var lang = localStorage.getItem('Lang')
    if (lang == null || lang == undefined)
      lang = 'ua'

    this.translate.use(lang)
    this.langSelect = lang
  }

  logout(): void{

    this.auth.logout()
  }

  onChangeLang(selectValue) {
    this.translate.use(selectValue)
    localStorage.setItem('Lang', selectValue)
    
  }
}
