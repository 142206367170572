import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-marketblockright',
  templateUrl: './marketblockright.component.html',
  styleUrls: ['./marketblockright.component.css']
})
export class MarketblockrightComponent implements OnInit {

  @Input('date_s') date_s;
  @Input('date_e') date_e;
  @Input('data_codeExcel') data_codeExcel;

  @Input('market_name') market_name;

  @Input('area1_name') area1_name;

  @Input('var_desc_aver1') var_desc_aver1;
  @Input('var_val_aver1') var_val_aver1;
  @Input('var_units_aver1') var_units_aver1;

  @Input('var_desc_aver2') var_desc_aver2;
  @Input('var_val_aver2') var_val_aver2;
  @Input('var_units_aver2') var_units_aver2;
 
  @Input('area2_name') area2_name;

  @Input('var_desc_aver3') var_desc_aver3;
  @Input('var_val_aver3') var_val_aver3;
  @Input('var_units_aver3') var_units_aver3;

  @Input('var_desc_aver4') var_desc_aver4;
  @Input('var_val_aver4') var_val_aver4;
  @Input('var_units_aver4') var_units_aver4;
  
  constructor() { }

  ngOnInit(): void {
  }

}
