<div class="row">
    <div class="col-sm-3">
        <app-markettype
            [date_s] = "date_s"
            [date_e] = "date_e"
            [data_codeExcel] = "data_codeExcel"
            [var_desc] = "market_name"
        ></app-markettype>
        
    </div>
    <div class="col-sm-3">
        <app-marketarea
            [var_desc] = "area1_name"
        ></app-marketarea>    
        <app-marketarea
            [var_desc] = "area2_name"
        ></app-marketarea>
    </div>
    <div class="col-sm-3">
        <app-marketitem
            [var_desc] = "var_desc11"
            [var_val] = "var_val11"
            [var_units] = "var_units11"
        ></app-marketitem>
        <app-marketitem
            [var_desc] = "var_desc12"
            [var_val] = "var_val12"
            [var_units] = "var_units12"
        ></app-marketitem>
        <app-marketitem
            [var_desc] = "var_desc13"
            [var_val] = "var_val13"
            [var_units] = "var_units13"
        ></app-marketitem>
        <app-marketitem
            [var_desc] = "var_desc21"
            [var_val] = "var_val21"
            [var_units] = "var_units21"
        ></app-marketitem>
        <app-marketitem
            [var_desc] = "var_desc22"
            [var_val] = "var_val22"
            [var_units] = "var_units22"
        ></app-marketitem>
        <app-marketitem
            [var_desc] = "var_desc23"
            [var_val] = "var_val23"
            [var_units] = "var_units23"
        ></app-marketitem>
    </div>
    <div class="col-sm-3">
        <app-marketitem
            [var_desc] = "var_desc_b1_1_7"
            [var_val] = "var_val_b1_1_7"
            [var_units] = "var_units_b1_1_7"
        ></app-marketitem>
        <app-marketitem
            [var_desc] = "var_desc_b1_8_22"
            [var_val] = "var_val_b1_8_22"
            [var_units] = "var_units_b1_8_22"
        ></app-marketitem>
        <app-marketitem
            [var_desc] = "var_desc_b1_23_24"
            [var_val] = "var_val_b1_23_24"
            [var_units] = "var_units_b1_23_24"
        ></app-marketitem>
        <app-marketitem
            [var_desc] = "var_desc_b2_1_7"
            [var_val] = "var_val_b2_1_7"
            [var_units] = "var_units_b2_1_7"
        ></app-marketitem>
        <app-marketitem
            [var_desc] = "var_desc_b2_8_22"
            [var_val] = "var_val_b2_8_22"
            [var_units] = "var_units_b2_8_22"
        ></app-marketitem>
        <app-marketitem
            [var_desc] = "var_desc_b2_23_24"
            [var_val] = "var_val_b2_23_24"
            [var_units] = "var_units_b2_23_24"
        ></app-marketitem>
    </div>
</div>

