<div class="row">
    <div class="col-lg-6" >{{col1Name}}</div>
    <div class="col-lg-2" >Тарифи на послуги, грн/МВт·год </div>
    <div class="col-lg-2" >Постанова НКРЕКП, №</div>
    <div class="col-lg-2" >Постанова НКРЕКП, дата</div>
</div>

<div class="row myrow" *ngFor="let pup of pupList">
    <div class="col-lg-6" >{{pup.name}}</div>
    <div class="col-lg-2" >{{pup.class1}}</div>
    <div class="col-lg-2" >{{pup.post}}</div>
    <div class="col-lg-2" >{{pup.postdate}}</div>
</div>
