<div *ngFor="let osr of osrList">
    <div class="row osrName" >
        <span class="pseudolink"  (click)="osrClick(osr.id)">{{osr.name}}</span>
    </div>
    <div class="row">
        <div class="col-lg-6 divmap" >
            <div class="centr">1 клас</div>
            <div class="centr">{{osr.class1}}</div>
        </div>
        <div class="col-lg-6 divmap" >
            <div class="row centr">2 клас</div>
            <div class="row centr">{{osr.class2}}</div>
        </div>
    </div>
    <div class="row">
        <div class="row centr">Постанова {{osr.post}} від {{osr.postdate}}</div>
    </div>
</div>

