<div class="row">
    <div class="col-sm-4">
        <app-markettype
            [date_s] = "date_s"
            [date_e] = "date_e"
            [data_codeExcel] = "data_codeExcel"
            [var_desc] = "market_name"
        ></app-markettype>
        
    </div>
    <div class="col-sm-4">
        <app-marketarea
            [var_desc] = "area1_name"
        ></app-marketarea>    
        <app-marketarea
            [var_desc] = "area2_name"
        ></app-marketarea>
    </div>
    <div class="col-sm-4">
        <app-marketitem
            [var_desc] = "var_desc_aver1"
            [var_val] = "var_val_aver1"
            [var_units] = "var_units_aver1"
        ></app-marketitem>       
        <app-marketitem
            [var_desc] = "var_desc_aver2"
            [var_val] = "var_val_aver2"
            [var_units] = "var_units_aver2"
            ></app-marketitem>
            <app-marketitem
            [var_desc] = "var_desc_aver3"
            [var_val] = "var_val_aver3"
            [var_units] = "var_units_aver3"
        ></app-marketitem>       
        <app-marketitem
            [var_desc] = "var_desc_aver4"
            [var_val] = "var_val_aver4"
            [var_units] = "var_units_aver4"
        ></app-marketitem>
    </div>
</div>


