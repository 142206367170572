<div class="divdate">
    <mat-form-field appearance="fill">
        <mat-label>{{ 'SHARED.SELECTMONTH' | translate }}</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp
                        startView="multi-year"
                        (yearSelected)="chosenYearHandler($event)"
                        (monthSelected)="chosenMonthHandler($event, dp)"
                        panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>    
</div>

<div class="row"><h3 style="margin: auto;">Загальна інформація</h3></div>

<div class="row row-sm mb-3">
    <div class="col-sm-4">
        <h2 class="card-title card-title-sm">Кількість об'єктів ВДЕ, шт</h2>
        <div id="chartdivl" style="width: 100%; height: 400px"></div>
        <div id="clegend" style="width: 100%; height: 300px;">

        </div>
    </div>
    <div class="col-sm-4">
        <h2 class="card-title card-title-sm">Встановлена потужність, МВт.год</h2>
        <div id="chartdiv2" style="width: 100%; height: 400px"></div>
        <div id="clegend2" style="width: 100%; height: 300px;">

        </div>
    </div>
    <div class="col-sm-4">
        <h2 class="card-title card-title-sm">Фактичний відпуск, МВт.год</h2>
        <div id="chartdiv3" style="width: 100%; height: 400px"></div>
        <div id="clegend3" style="width: 100%; height: 300px;">

        </div>
    </div>
</div>



