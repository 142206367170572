import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as myURL from '../../api-endpoints';

@Component({
  selector: 'app-maininfo',
  templateUrl: './maininfo.component.html',
  styleUrls: ['./maininfo.component.css']
})
export class MaininfoComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  getPageName(inURL : string): string {
    let retURL = inURL;
    let lInd = inURL.lastIndexOf('/');
    if (lInd + 1 == inURL.length){
      retURL = inURL.substring(0, lInd);
    }

    lInd = retURL.lastIndexOf('/');
    if (lInd > 0){
      retURL = retURL.substring(lInd);
    }
    return retURL;
  }

  setPageWholePrice(){
    this.router.navigate([this.getPageName(myURL.API_WHOLESALE_MARKET_PRICES)]);
  }

}
