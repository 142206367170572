<div class="divdate">
    <mat-form-field appearance="fill">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker"  (dateChange)="updateDate($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>

<div class="row"><h3 style="margin: auto;">Прогнозування погодинних цін на РДН у торговій зоні "ОЕС України" на базі моделі ШНМ, грн/Мвт∙год</h3></div>

<div class="row">
    <div class="col-lg-10 divchart" >
        <div id="chartdiv" style="width: 100%; height: 600px"></div>
    </div>
    <div class="col-lg-2 divtable" >
        <div class="row">
            <button class="buttonExcel" (click)="performDownload()"></button>
        </div>

    <div class="row rtab">
        <div class="col-lg-3 divmap" >
            <div class="centr">Година</div>
        </div>
        <div class="col-lg-4 divmap" >
            <div class="centr">Значення</div>
        </div>
        <div class="col-lg-4 divmap" >
            <div class="centr">Відхилення</div>
        </div>
    </div>

    <div *ngFor="let val of graphVals">
        <div class="row rtab">
            <div class="col-lg-3 divmap" >
                <div class="centr">{{val.hour}}</div>
            </div>
            <div class="col-lg-4 divmap" >
                <div class="centr">{{val.val}}</div>
            </div>
            <div class="col-lg-4 divmap" >
                <div class="centr">{{val.valproc}}</div>
            </div>
        </div>
    </div>
                
    </div>
</div>
