import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';

import {OSRList} from '../../pages/retail-market-generalinfo/retail-market-generalinfo.component';

@Component({
  selector: 'app-osr',
  templateUrl: './osr.component.html',
  styleUrls: ['./osr.component.css']
})
export class OSRComponent implements OnInit {

@Input()  osrList: OSRList[];
@Input() osrChange:boolean
@Output() onOSR: EventEmitter<any> = new EventEmitter<any>();

  constructor(private ref: ChangeDetectorRef) { 
    
    setInterval(() => {
      // the following is required, otherwise the view will not be updated
      if (this.osrChange === true)
      {
        this.ref.markForCheck();
        this.osrChange = false;
       }}, 100);  
  }

  ngOnInit(): void {
    this.osrChange = false
  }

  osrClick(id: number): void {
    this.onOSR.emit(id);
    console.log('osrClick-comp', id)
  }

}
