import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import {
  API_RETAIL_MARKET_GENERALINFO, API_RETAIL_MARKET_PUPINFO
} from '../../api-endpoints';

import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface OSRList{
  id: number
  name: string
  class1: string
  class2: string
  post: string
  postdate: string
}

import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-retail-market-generalinfo',
  templateUrl: './retail-market-generalinfo.component.html',
  styleUrls: ['./retail-market-generalinfo.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],    
})


export class RetailMarketGeneralinfoComponent implements OnInit {

  year: string
  month: string
  id: number

  dtPrice: any
  marketType: any
  data: any
  
  date = new FormControl(moment());

  public osrList: OSRList[] = []
  public pupList: OSRList[] = []
  public ospList: OSRList[] = []
  public infostr: string;
  public osrChange: boolean
  public pupChange: boolean

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.osrChange = false
    this.pupChange = false
    const year = new Date().getFullYear()
    this.year = year.toString()
    const month = new Date().getMonth()
    this.month = month.toString()
    console.log(this.month + '/' + this.year)
    this.createRequestPUP(0)

  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;

    let year = normalizedYear.year()
    this.year = year.toString()
    
    ctrlValue.year(year);
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.date.value;
    let month = normalizedMonth.month()
    this.month = (month + 1).toString()
    if (this.month.length < 2)
    {
      this.month = '0' + this.month
    }
    
    ctrlValue.month(month);
    this.date.setValue(ctrlValue);

    this.createRequest(this.id)
    this.createRequestPUP(0)
    this.osrChange = true
  }  

  onNewArea(id: number){
    console.log(id)
    this.infostr = 'Click ' + id.toString()
    this.id = id
    const json = this.createRequest(id)
    this.osrChange = true
  }
    
  onOSR(id: number) {
    console.log('osrClick-page', id)
    this.createRequestPUP(id)
    this.pupChange = true    
  }

  createRequestPUP(array_id)
  {
    console.log('createRequestPUP', array_id)

    this.http.get<any>(API_RETAIL_MARKET_PUPINFO, {
      params:{
        date: this.month + "/" + this.year,
        array_id: array_id
      },
      observe: 'response'}).subscribe(response => {
      this.data = response.body;
      console.log('***')
      console.log(response.body)
      if (array_id == 0)
      {
        this.ospList = JSON.parse(JSON.stringify(response.body))
        console.log(this.ospList)
      }
      else
      {
        this.pupList = JSON.parse(JSON.stringify(response.body))
      }
      console.log('---')
      return response.body
    });
    return this.data
  }
    
  createRequest(array_id)
  {
    this.http.get<any>(API_RETAIL_MARKET_GENERALINFO, {
      params:{
        date: this.month + "/" + this.year,
        array_id: array_id
      },
      observe: 'response'}).subscribe(response => {
      this.data = response.body;
      console.log('***')
      console.log(response.body)
      this.osrList = JSON.parse(JSON.stringify(response.body))
      console.log('---')
      return response.body
    });
    return this.data
  }
}
