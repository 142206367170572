import {environment} from '../environments/environment';

const BASE_URL = environment.BASE_URL;
const API = 'api'

export const JWT_LOGIN = `${BASE_URL}/${API}/login/`
export const JWT_REFRESH_TOKEN = `${BASE_URL}/${API}/refresh_token/`
export const JWT_LOGOUT = `${BASE_URL}/${API}/logout/`
export const API_USERS_LIST = `${BASE_URL}/${API}/users/`
export const API_WHOLESALE_MARKET_PRICES = `${BASE_URL}/${API}/wholesale-market-prices/`
export const API_WHOLESALE_MARKET_PRICESRDD = `${BASE_URL}/${API}/wholesale-market-pricesrdd/`
export const API_WHOLESALE_MARKET_VOLUMES = `${BASE_URL}/${API}/wholesale-market-volumes/`
export const API_WHOLESALE_MARKET_VOLUMESRDD = `${BASE_URL}/${API}/wholesale-market-volumesrdd/`
export const API_WHOLESALE_MARKET_PRICES_DOWNLOAD_AS_EXEL = `${BASE_URL}/${API}/wholesale-market-prices/download-exel/`
export const API_RETAIL_MARKET_GENERALINFO = `${BASE_URL}/${API}/retail-market-generalinfo/`
export const API_RETAIL_MARKET_PUPINFO = `${BASE_URL}/${API}/retail-market-pupinfo/`
export const API_RETAIL_MARKET_MAPPRICE = `${BASE_URL}/${API}/retail-market-mapprice/`
export const API_WHOLESALE_MARKET_INSTALLCAPACITY = `${BASE_URL}/${API}/wholesale-market-installcapacity/`
export const API_WHOLESALE_MARKET_BLOCKS = `${BASE_URL}/${API}/wholesale-market-blocks/`
export const API_WHOLESALE_MARKET_BLOCKSARCHIV = `${BASE_URL}/${API}/wholesale-market-blocksarchiv/`
export const API_FORECAST_RDN = `${BASE_URL}/${API}/forecast-rdn/`
export const API_FORECAST_DEMAND = `${BASE_URL}/${API}/forecast-demand/`
export const API_MANUFACTURERS = `${BASE_URL}/${API}/manufacturers/`
export const API_TABLETS = `${BASE_URL}/${API}/tablets/`



