import { Component, OnInit, NgZone, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DatePipe } from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {DateAdapter} from '@angular/material/core';

import {
  API_WHOLESALE_MARKET_BLOCKS, API_WHOLESALE_MARKET_BLOCKSARCHIV
} from '../../api-endpoints';

// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
//import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4geodata_UAHigh from "@amcharts/amcharts4-geodata/ukraineHigh";

import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import {FormControl} from '@angular/forms';


export interface BlocksList{
  id: number
  name: string
  val: string
}

@Component({
  selector: 'app-manufacturers',
  templateUrl: './manufacturers.component.html',
  styleUrls: ['./manufacturers.component.css']
})



export class ManufacturersComponent implements OnInit {

   private chart: am4maps.MapChart;

   public blockList: BlocksList[] = []

  dateS: any
  dateE: any  

  dtStart: string;
  dtEnd: string;
  data: any

  blockArchiv: any

  graphchart: any
  dataChart: any = [];
  imageSeries: any
  imageSeriesTemplate: any;
  marker: any;

  imageSeries1: any
  imageSeriesTemplate1: any;
  marker1: any;

  imageSeries2: any
  imageSeriesTemplate2: any;
  marker2: any;

  StationName: any
  StationVal: any

  comboTypeData: any;

  AESval:any
  TESval:any
  TECval:any

  constructor(@Inject(PLATFORM_ID) private platformId, private _adapter: DateAdapter<any>, private http: HttpClient, private zone: NgZone, public datepipe: DatePipe) { 
  }

   // Run the function only in the browser
   browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit(): void {
    this.comboTypeData = [{"value": 0, "label": "Всі"},{"value": 1, "label": "АЕС"},{"value": 2, "label": "ТЕС"},{"value": 3, "label": "ТЕЦ"}]


    setTimeout(() => {
      //am4core.useTheme(am4themes_animated);
      this.graphchart = am4core.create("graphdiv", am4charts.XYChart);
      this.graphchart.data = this.dataChart;
  
      // Create axes
      let dateAxis = this.graphchart.xAxes.push(new am4charts.DateAxis());
  
      dateAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
      dateAxis.renderer.grid.template.stroke = am4core.color("#FFFFFF");
  
      let valueAxis = this.graphchart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
      valueAxis.renderer.grid.template.stroke = am4core.color("#FFFFFF");
  
      // Create series
      let graphseries = this.graphchart.series.push(new am4charts.LineSeries());
      graphseries.dataFields.valueY = "value";
      graphseries.dataFields.dateX = "date";
      graphseries.tooltipText = "{value}"
  
      graphseries.tooltip.pointerOrientation = "vertical";
  
      this.graphchart.cursor = new am4charts.XYCursor();
      this.graphchart.cursor.snapToSeries = graphseries;
      this.graphchart.cursor.xAxis = dateAxis;
  
      //chart.scrollbarY = new am4core.Scrollbar();
      this.graphchart.scrollbarX = new am4core.Scrollbar();
    }, 0)
  

    this._adapter.setLocale('ukr');
    this.initDate();
    this.createRequest();
    this.createRequestBlocks();
  }

  createRequest()  {
    setInterval( () => {
      this.createRequestBlocks()
      }, 30000);
  }

  createRequestBlocks()
  {
    console.log('createRequestBlocks')

    this.http.get<any>(API_WHOLESALE_MARKET_BLOCKS, {
      params:{
      },
      observe: 'response'}).subscribe(response => {
      this.data = response.body;
 //     console.log('***')
      console.log(response.body)
      this.blockList = JSON.parse(JSON.stringify(response.body))

      let blockList = JSON.parse(JSON.stringify(response.body))
      let keyCount  = Object.keys(blockList).length;
      
      this.StationVal = undefined;
      this. AESval = 0;
      this. TECval = 0;
      this. TESval = 0;
      for(var k in blockList) {
       // console.log(k, blockList[k]);
        let id = blockList[k]['id'];
        if (id == this.blockArchiv){
          this.StationVal = blockList[k]['val']

        //  console.log(this.StationVal)
        }

        if (id == 1 || id == 2 || id == 3 || id == 4){
          this.AESval += blockList[k]['val'];
        }
        if (id == 5 || id == 6 || id == 7 || id == 8 || id == 9 || id == 10 || id == 11 || id == 12 || id == 14 || id == 17 || id == 18 || id == 19){
          this.TESval += blockList[k]['val'];
        }
        if (id == 13 || id == 15 || id == 16){
          this.TECval += blockList[k]['val'];
        }
      }
      console.log(this.AESval);
      return response.body
    });
    return this.data
  }


  ngAfterViewInit() {
    setTimeout(() => {

    // Chart code goes in here
    this.browserOnly(() => {
//      am4core.useTheme(am4themes_animated);

      am4core.options.autoDispose = true;

      let chart = am4core.create("chartdiv", am4maps.MapChart);
      chart.geodata = am4geodata_UAHigh;
      
      chart.projection = new am4maps.projections.Miller();

      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
      polygonSeries.useGeodata = true

      let polygonTemplate = polygonSeries.mapPolygons.template;

      polygonTemplate.polygon.fillOpacity = 0.6;
      polygonTemplate.fill = am4core.color("#3a414c");
      polygonTemplate.stroke = am4core.color("#656b72");

      let hs = polygonTemplate.states.create("hover");
      
      hs.properties.fill = am4core.color("#da2060");

      // Create image series
      this.imageSeries = chart.series.push(new am4maps.MapImageSeries());
      this.imageSeriesTemplate = this.imageSeries.mapImages.template;
      this.marker = this.imageSeriesTemplate.createChild(am4core.Image);
      this.marker.width = 30;
      this.marker.height = 30;
      this.marker.horizontalCenter = "middle";
      this.marker.verticalCenter = "middle";
      this.marker.nonScaling = true;
      this.marker.tooltipText = "{title}";
      this.marker.clickable = true;
      this.marker.href = "../../../assets/image/tes.png";
      this.marker.cursorOverStyle = am4core.MouseCursorStyle.pointer
      this.imageSeries.interactionsEnabled = true;

      this.marker.events.on("hit", function(ev) {
        let mark = ev.target.dataItem.dataContext;
        console.log("clicked on ", mark['id']);
        this.blockArchiv = mark['id'];
        this.StationName = mark['title'];
        this.requestArchiv()
        this.createRequestBlocks();
    
      }, this);
      // Set property fields
      this.imageSeriesTemplate.propertyFields.latitude = "latitude";
      this.imageSeriesTemplate.propertyFields.longitude = "longitude";



      // Create image series
      this.imageSeries1 = chart.series.push(new am4maps.MapImageSeries());
      this.imageSeriesTemplate1 = this.imageSeries1.mapImages.template;
      this.marker1 = this.imageSeriesTemplate1.createChild(am4core.Image);
      this.marker1.width = 30;
      this.marker1.height = 30;
      this.marker1.horizontalCenter = "middle";
      this.marker1.verticalCenter = "middle";
      this.marker1.nonScaling = true;
      this.marker1.tooltipText = "{title}";
      this.marker1.clickable = true;
      this.marker1.href = "../../../assets/image/tes.png";
      this.marker1.cursorOverStyle = am4core.MouseCursorStyle.pointer
      this.imageSeries1.interactionsEnabled = true;

      this.marker1.events.on("hit", function(ev) {
        let mark = ev.target.dataItem.dataContext;
        console.log("clicked on ", mark['id']);
        this.blockArchiv = mark['id'];
        this.StationName = mark['title'];
        this.requestArchiv()
        this.createRequestBlocks();
    
      }, this);
      // Set property fields
      this.imageSeriesTemplate1.propertyFields.latitude = "latitude";
      this.imageSeriesTemplate1.propertyFields.longitude = "longitude";


      // Create image series
      this.imageSeries2 = chart.series.push(new am4maps.MapImageSeries());
      this.imageSeriesTemplate2 = this.imageSeries2.mapImages.template;
      this.marker2 = this.imageSeriesTemplate2.createChild(am4core.Image);
      this.marker2.width = 30;
      this.marker2.height = 30;
      this.marker2.horizontalCenter = "middle";
      this.marker2.verticalCenter = "middle";
      this.marker2.nonScaling = true;
      this.marker2.tooltipText = "{title}";
      this.marker2.clickable = true;
      this.marker2.href = "../../../assets/image/tes.png";
      this.marker2.cursorOverStyle = am4core.MouseCursorStyle.pointer
      this.imageSeries2.interactionsEnabled = true;

      this.marker2.events.on("hit", function(ev) {
        let mark = ev.target.dataItem.dataContext;
        console.log("clicked on ", mark['id']);
        this.blockArchiv = mark['id'];
        this.StationName = mark['title'];
        this.requestArchiv()
        this.createRequestBlocks();
    
      }, this);
      // Set property fields
      this.imageSeriesTemplate2.propertyFields.latitude = "latitude";
      this.imageSeriesTemplate2.propertyFields.longitude = "longitude";


      this.chart = chart;
      this.createSeries()

      });
    }, 0)

  }

  onOptionsSelected(value){
    console.log(value)
    if (value == 0)
    {
      this.imageSeries.show();
      this.imageSeries1.show();
      this.imageSeries2.show();
    }
    if (value == 1)
    {
      this.imageSeries.show();
      this.imageSeries1.hide();
      this.imageSeries2.hide();
    }
    if (value == 2)
    {
      this.imageSeries.hide();
      this.imageSeries1.show();
      this.imageSeries2.hide();
    }
    if (value == 3)
    {
      this.imageSeries.hide();
      this.imageSeries1.hide();
      this.imageSeries2.show();
    }
  }

  createSeries(){
    console.log('createSeries')
    this.imageSeries.data.push({
      "id": 1,
      "title": "Рівненська АЕС",  
      "latitude": 51.329382968120605,
      "longitude": 25.89389095374684
    }, {
      "id": 2,
      "title": "Запорізька АЕС",
      "latitude": 47.50666864231529,
      "longitude": 34.54
    }, {
      "id": 3,
      "title": "Южно-Українська АЕС",
      "latitude": 47.81045264603608,
      "longitude": 31.220081678002018
    }, {
      "id": 4,
      "title": "Хмельницька АЕС",
      "latitude": 50.30242279202367, 
      "longitude": 26.652099367205977
    })

    this.imageSeries1.data.push({
      "id": -1,
      "title": "Старобешівська ТЕС",
      "latitude": 47.80069,
      "longitude": 38.0056572
    },{
      "id": -1,
      "title": "Зуївська ТЕС",
      "latitude": 48.0331,
      "longitude": 38.28615
    },  {
      "id": 5,
      "title": "Луганська ТЕС",
      "latitude": 48.7478142,
      "longitude": 39.26239
    }, {
      "id": 6,
      "title": "Слов'янська ТЕС",
      "latitude": 48.8720551,
      "longitude": 37.7656746
    }, {
      "id": 7,
      "title": "Вуглегірська ТЕС",
      "latitude": 48.4640427,
      "longitude": 38.19723
    }, {
      "id": 8,
      "title": "Курахівська ТЕС",
      "latitude": 47.9944038,
      "longitude": 37.24022
    }, {
      "id": 9,
      "title": "Криворізька ТЕС",
      "latitude": 47.5392227,
      "longitude": 33.6618538
    }, {
      "id": 10,
      "title": "Придніпровська ТЕС",
      "latitude": 47.86406,
      "longitude": 35.1522675
    }, {
      "id": 11,
      "title": "Запорізька ТЕС",
      "latitude": 47.50949,
      "longitude": 34.6244431
    }, {
      "id": 12,
      "title": "Зміївська ТЕС",
      "latitude": 49.5874,
      "longitude": 36.53066
    }, {
      "id": 14,
      "title": "Трипільська ТЕС",
      "latitude": 50.13442,
      "longitude": 30.7468052
    }, {
      "id": 17,
      "title": "Ладижинська ТЕС",
      "latitude": 48.70671,
      "longitude": 29.2202
    }, {
      "id": 18,
      "title": "Бурштинська ТЕС",
      "latitude": 49.20958,
      "longitude": 24.6627617
    }, {
      "id": 19,
      "title": "Добротворська ТЕС",
      "latitude": 50.2132721,
      "longitude": 24.3749714
    })

    this.imageSeries2.data.push({
      "id": 13,
      "title": "Харківська ТЕЦ-5",
      "latitude": 49.969635,
      "longitude": 36.10734
    }, {
      "id": 15,
      "title": "Київська ТЕЦ-5",
      "latitude": 50.3935547,
      "longitude": 30.5687714
    }, {
      "id": 16,
      "title": "Київська ТЕЦ-6",
      "latitude": 50.5320969,
      "longitude": 30.6628628
    }, {
      "id": -1,
      "title": "Калузька ТЕЦ",
      "latitude": 49.07101,
      "longitude": 24.32005
    })

    console.log(this.imageSeries.data)
    /*
    this.imageSeries.data = [ 
  ]
  */

  }
  

  initDate(): void {
    var dateSString = localStorage.getItem('dtS')
    console.log(dateSString)
    if (dateSString === undefined || dateSString === null)
      dateSString = '01.01.2021'
  
    if (dateSString.length == 10) {
      var arrS = dateSString.split(".")
      this.dateS = new FormControl(new Date(parseInt(arrS[2]), parseInt(arrS[1]) - 1, parseInt(arrS[0])));
      var mon = parseInt(arrS[1]) - 1
      this.dtStart = arrS[0] + '/' + arrS[1] + '/' + arrS[2]
    }
    else {
      this.dateS = new FormControl(new Date());
    }
  
    var dateEString = localStorage.getItem('dtE')
  
    if (dateEString === undefined || dateEString === null)
      dateEString = '01.01.2021'
  
    if (dateEString.length == 10) {
      var arrE = dateEString.split(".")
      this.dateE = new FormControl(new Date(parseInt(arrE[2]), parseInt(arrE[1]) - 1, parseInt(arrE[0])));
      this.dtEnd = arrE[0] + '/' + arrE[1] + '/' + arrE[2]
    }
    else {
      this.dateE = new FormControl(new Date());
    }
  }
  
  saveToLocalStorage()
  {
    var dtLocStorS = this.dtStart
    var dtLocStorE = this.dtEnd

    for (var i = 0; i < 2; i++)
    {
      dtLocStorS = dtLocStorS.replace('/','.')
      dtLocStorE = dtLocStorE.replace('/','.')
    }
    localStorage.setItem('dtS', dtLocStorS)
    localStorage.setItem('dtE', dtLocStorE)
  }

  setStart(event: any): void {
    if (event.value != null)
    {
      console.log("Start", event.value);
      this.dtStart = this.datepipe.transform(event.value, 'dd/MM/yyyy')
    }
  }

  setEnd(event: any): void {
    if (event.value != null)
    {
      this.dtEnd = this.datepipe.transform(event.value, 'dd/MM/yyyy')
      this.requestArchiv();
      this.saveToLocalStorage()
    }
  }

  blockClick(id){
    var data = this.imageSeries.data
    for (var i in data)
    {
      if (data[i].id == id)
      {
        this.StationName = data[i].title;
        this.blockArchiv = id
        this.requestArchiv();
        this.createRequestBlocks();
        return;
      }
    }
    data = this.imageSeries1.data
    for (var i in data)
    {
      if (data[i].id == id)
      {
        this.StationName = data[i].title;
        this.blockArchiv = id
        this.requestArchiv();
        this.createRequestBlocks();
        return;
      }
    }
    data = this.imageSeries2.data
    for (var i in data)
    {
      if (data[i].id == id)
      {
        this.StationName = data[i].title;
        this.blockArchiv = id
        this.requestArchiv();
        this.createRequestBlocks();
        return;
      }
    }
  }

  requestArchiv(){
    this.http.get<any>(API_WHOLESALE_MARKET_BLOCKSARCHIV, {
      params:{
        ds: this.dtStart,
        de: this.dtEnd,
        datacoll: this.blockArchiv
      },
      observe: 'response'}).subscribe(response => {
      this.data = response.body;
      let dat = JSON.parse(JSON.stringify(response.body))
      let dataChart = []
      for (let i = 0; i < dat.length; i++){
        let date = new Date(dat[i].date); 
        dataChart.push({date:date, value: dat[i].value});
      }

      this.graphchart.data = dataChart;
      console.log(dataChart.length)
      return response.body
    });

  }

  addNewDate(event: any): void {
    console.log("addNewDate", event.value);

  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

}
