import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../auth/services/auth.service';
import { DatePipe } from '@angular/common';
import {DateAdapter} from '@angular/material/core';

import {
  API_WHOLESALE_MARKET_VOLUMES
} from '../../api-endpoints';

import {
  API_WHOLESALE_MARKET_PRICES, API_WHOLESALE_MARKET_VOLUMESRDD
} from '../../api-endpoints';

import {API_WHOLESALE_MARKET_PRICES_DOWNLOAD_AS_EXEL} from '../../api-endpoints';


import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-wholesale-market-volumes',
  templateUrl: './wholesale-market-volumes.component.html',
  styleUrls: ['./wholesale-market-volumes.component.css']
})
export class WholesaleMarketVolumesComponent implements OnInit {
  data: any;
  dataRDD: any;

  dtStart: string;
  dtEnd: string;

  BASE1: string;
  PEAK1: string;
  OFFP1: string;
  B1_1_7: string;
  B1_8_22: string;
  B1_23_24: string;

  BASE2: string;
  PEAK2: string;
  OFFP2: string;
  B2_1_7: string;
  B2_8_22: string;
  B2_23_24: string;

  BASE3: string;
  PEAK3: string;
  OFFP3: string;
  AVER3: string;

  BASE4: string;
  PEAK4: string;
  OFFP4: string;
  AVER4: string;

  BASE5: string;
  PEAK5: string;
  OFFP5: string;
  AVER5: string;

  BASE6: string;
  PEAK6: string;
  OFFP6: string;
  AVER6: string;

  AVER7: string;
  AVER8: string;
  AVER9: string;
  AVER10: string;

  BASE7: string;
  BASE8: string;
  BASE9: string;
  BASE10: string;

  AVER11: string;
  AVER12: string;
  AVER13: string;
  AVER14: string;


  dateS: any
  dateE: any

  constructor(private http: HttpClient, public auth: AuthService, private _adapter: DateAdapter<any> , public datepipe: DatePipe) { }

  ngOnInit(): void {

    this.dtStart = '';
    this.dtEnd = '';

    this.BASE1 = '-';
    this.PEAK1 = '-';
    this.OFFP1 = '-';
    this.B1_1_7 = '-';
    this.B1_8_22 = '-';
    this.B1_23_24 = '-';

    this.BASE2 = '-';
    this.PEAK2 = '-';
    this.OFFP2 = '-';
    this.B2_1_7 = '-';
    this.B2_8_22 = '-';
    this.B2_23_24 = '-';

    this.BASE3 = '-';
    this.PEAK3 = '-';
    this.OFFP3 = '-';
    this.AVER3 = '-';

    this.BASE4 = '-';
    this.PEAK4 = '-';
    this.OFFP4 = '-';
    this.AVER4 = '-';

    this.BASE5 = '-';
    this.PEAK5 = '-';
    this.OFFP5 = '-';
    this.AVER5 = '-';

    this.BASE6 = '-';
    this.PEAK6 = '-';
    this.OFFP6 = '-';
    this.AVER6 = '-';

    this.AVER7 = '-';
    this.AVER8 = '-';
    this.AVER9 = '-';
    this.AVER10 = '-';

    this.BASE7 = '-';
    this.BASE8 = '-';
    this.BASE9 = '-';
    this.BASE10 = '-';

    this.AVER11 = '-';
    this.AVER12 = '-';
    this.AVER13 = '-';
    this.AVER14 = '-';
    
    this._adapter.setLocale('ukr');

    this.initDate()
    this.createRequest()
    this.createRequestRDD()
  }

  getFormattedDate(date) {
    let year = date.getYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return day + '/' + month + '/' + year;
  }

  initDate(): void {
    var dateSString = localStorage.getItem('dtS')

    if (dateSString === undefined || dateSString === null)
      dateSString = '01.01.2021'

    if (dateSString.length == 10) {
      var arrS = dateSString.split(".")
      this.dateS = new FormControl(new Date(parseInt(arrS[2]), parseInt(arrS[1]) - 1, parseInt(arrS[0])));
      var mon = parseInt(arrS[1]) - 1
      this.dtStart = arrS[0] + '/' + arrS[1] + '/' + arrS[2]
    }
    else {
      this.dateS = new FormControl(new Date());
    }

    var dateEString = localStorage.getItem('dtE')

    if (dateEString === undefined || dateEString === null)
      dateEString = '01.01.2021'

    if (dateEString.length == 10) {
      var arrE = dateEString.split(".")
      this.dateE = new FormControl(new Date(parseInt(arrE[2]), parseInt(arrE[1]) - 1, parseInt(arrE[0])));
      this.dtEnd = arrE[0] + '/' + arrE[1] + '/' + arrE[2]
    }
    else {
      this.dateE = new FormControl(new Date());
    }
  }

  createRequestRDD(): void {
    if (this.dtStart.length < 8 || this.dtEnd.length < 8)
      return;

    this.http.get<any>(API_WHOLESALE_MARKET_VOLUMESRDD, {
      params:{
        ds: this.dtStart,
        de: this.dtEnd,
        datacoll:'1'
      },
      observe: 'response'}).subscribe(response => {
      this.dataRDD = response.body;

      console.log('Responce:', this.dataRDD);
      console.log('BASE1=', this.dataRDD.BASE1);

      this.BASE1 = this.getValue(this.dataRDD.BASE1);
      this.PEAK1 = this.getValue(this.dataRDD.PEAK1);
      this.OFFP1 = this.getValue(this.dataRDD.OFFP1);
      this.B1_1_7 = this.getValue(this.dataRDD.B1_1_7);
      this.B1_8_22 = this.getValue(this.dataRDD.B1_8_22);
      this.B1_23_24 = this.getValue(this.dataRDD.B1_23_24);

      this.BASE2 = this.getValue(this.dataRDD.BASE2);
      this.PEAK2 = this.getValue(this.dataRDD.PEAK2);
      this.OFFP2 = this.getValue(this.dataRDD.OFFP2);
      this.B2_1_7 = this.getValue(this.dataRDD.B2_1_7);
      this.B2_8_22 = this.getValue(this.dataRDD.B2_8_22);
      this.B2_23_24 = this.getValue(this.dataRDD.B2_23_24);
    });

  }


  createRequest(): void {
    if (this.dtStart.length < 8 || this.dtEnd.length < 8){
      return;
    }

    this.http.get<any>(API_WHOLESALE_MARKET_PRICES, {
      params:{
        ds: this.dtStart,
        de: this.dtEnd,
        datacoll:'2'
      },
      observe: 'response'}).subscribe(response => {
      this.data = response.body;

      console.log('>>>>>>>>>', this.data);
/*
      this.BASE1 = this.getValue(this.data.BASE1);
      this.PEAK1 = this.getValue(this.data.PEAK1);
      this.OFFP1 = this.getValue(this.data.OFFP1);
      this.AVER1 = this.getValue(this.data.AVER1);

      this.BASE2 = this.getValue(this.data.BASE2);
      this.PEAK2 = this.getValue(this.data.PEAK2);
      this.OFFP2 = this.getValue(this.data.OFFP2);
      this.AVER2 = this.getValue(this.data.AVER2);
*/
      this.BASE3 = this.getValue(this.data.BASE3);
      this.PEAK3 = this.getValue(this.data.PEAK3);
      this.OFFP3 = this.getValue(this.data.OFFP3);
      this.AVER3 = this.getValue(this.data.AVER3);

      this.BASE4 = this.getValue(this.data.BASE4);
      this.PEAK4 = this.getValue(this.data.PEAK4);
      this.OFFP4 = this.getValue(this.data.OFFP4);
      this.AVER4 = this.getValue(this.data.AVER4);

      this.BASE5 = this.getValue(this.data.BASE5);
      this.PEAK5 = this.getValue(this.data.PEAK5);
      this.OFFP5 = this.getValue(this.data.OFFP5);
      this.AVER5 = this.getValue(this.data.AVER5);

      this.BASE6 = this.getValue(this.data.BASE6);
      this.PEAK6 = this.getValue(this.data.PEAK6);
      this.OFFP6 = this.getValue(this.data.OFFP6);
      this.AVER6 = this.getValue(this.data.AVER6);

      this.AVER7 = this.getValue(this.data.AVER7);
      this.AVER8 = this.getValue(this.data.AVER8);
      this.AVER9 = this.getValue(this.data.AVER9);
      this.AVER10 = this.getValue(this.data.AVER10);

      this.BASE7 = this.getValue(this.data.BASE7);
      this.BASE8 = this.getValue(this.data.BASE8);
      this.BASE9 = this.getValue(this.data.BASE9);
      this.BASE10 = this.getValue(this.data.BASE10);

      this.AVER11 = this.getValue(this.data.AVER11);
      this.AVER12 = this.getValue(this.data.AVER12);
      this.AVER13 = this.getValue(this.data.AVER13);
      this.AVER14 = this.getValue(this.data.AVER14);

    });

  }

  getValue(inVal: any): string {
    if (inVal === undefined)
    {
      return '-'
    }
    var strVal = inVal.toString();
    if (strVal.length > 1)
    {
      var fVal = parseFloat(strVal);
      strVal = fVal.toFixed(2);
      strVal = strVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return strVal;
  }

  saveToLocalStorage()
  {
    var dtLocStorS = this.dtStart
    var dtLocStorE = this.dtEnd

    for (var i = 0; i < 2; i++)
    {
      dtLocStorS = dtLocStorS.replace('/','.')
      dtLocStorE = dtLocStorE.replace('/','.')
    }
    localStorage.setItem('dtS', dtLocStorS)
    localStorage.setItem('dtE', dtLocStorE)
  }

  setStart(event: any): void {
    if (event.value != null)
    {
      this.dtStart = this.datepipe.transform(event.value, 'dd/MM/yyyy')
    }
  }


  setEnd(event: any): void {
    if (event.value != null)
    {
      this.dtEnd = this.datepipe.transform(event.value, 'dd/MM/yyyy')
      this.createRequest();
      this.createRequestRDD()
      this.saveToLocalStorage()
    }
  }

  addNewDate(event: any): void {
    console.log("addNewDate", event.value);

  }

  performDownload(): void {
    this.http.get<any>(API_WHOLESALE_MARKET_PRICES_DOWNLOAD_AS_EXEL, {
      responseType: 'blob' as 'json',
      params: {
        date_start: this.dtStart,
        date_end: this.dtStart,
        data_code: '99'
      }
    }).subscribe(response => this.saveFile(response))

  }

  saveFile(response) {

    const ds_arr = this.dtStart.split('/')
    const de_arr = this.dtStart.split('/')
    console.log(ds_arr)
    const file_name = 'report_ALL_' +  ds_arr[2] +  ds_arr[1] +  ds_arr[0] + '-' + de_arr[2] +  de_arr[1] +  de_arr[0] + '.xlsx'

    const downloadURL = window.URL.createObjectURL(response);
    const link = document.createElement('a');

    link.href = downloadURL;
    link.download = file_name;
    link.click();
  }


}
