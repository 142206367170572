import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import {AppRoutingModule} from './routing/routing.module';
import { ProfileComponent } from './core/profile/profile.component';
import { MainComponent } from './core/main/main.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatInputModule} from '@angular/material/input'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatNativeDateModule} from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { DownloadExelComponent } from './core/download-exel/download-exel.component';
import { MarketitemComponent } from './components/marketitem/marketitem.component';
import { MarketareaComponent } from './components/marketarea/marketarea.component';
import { MarkettypeComponent } from './components/markettype/markettype.component';
import { MarketaverComponent } from './components/marketaver/marketaver.component';
import { MarketblockComponent } from './components/marketblock/marketblock.component';
import { ToolbarComponent } from './toolbar/toolbar/toolbar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MenuComponent } from './menu/menu/menu.component';

import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';

import { WholesaleMarketVolumesComponent } from './pages/wholesale-market-volumes/wholesale-market-volumes.component';
import { WholesaleMarketPricesComponent } from './pages/wholesale-market-prices/wholesale-market-prices.component';
import { MarketblockrightComponent } from './components/marketblockright/marketblockright.component';

import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { RetailMarketGeneralinfoComponent } from './pages/retail-market-generalinfo/retail-market-generalinfo.component';
import { RetailMarketMappriceComponent } from './pages/retail-market-mapprice/retail-market-mapprice.component';

import { ChartComponent } from './chart/chart.component';
import { MapEuropeComponent } from './map-europe/map-europe.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { OSRComponent } from './components/osr/osr.component';
import { PupComponent } from './components/pup/pup.component';
import { WholesaleMarketInstallcapacityComponent } from './pages/wholesale-market-installcapacity/wholesale-market-installcapacity.component';
import { VDEGeneralComponent } from './pages/vde-general/vde-general.component';
import { DAMDemandComponent } from './pages/dam-demand/dam-demand.component';
import { WholesaleMarketBlocksComponent } from './pages/wholesale-market-blocks/wholesale-market-blocks.component';
import { ForecastRdnComponent } from './pages/forecast-rdn/forecast-rdn.component';
import { MarketblockrddComponent } from './components/marketblockrdd/marketblockrdd.component';
import { ManufacturersComponent } from './pages/manufacturers/manufacturers.component';
import { TabletsComponent } from './pages/tablets/tablets.component';
import { MaininfoComponent } from './pages/maininfo/maininfo.component';
//import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    MainComponent,
    DownloadExelComponent,
    MarketitemComponent,
    MarketareaComponent,
    MarkettypeComponent,
    MarketaverComponent,
    MarketblockComponent,
    ToolbarComponent,
    MenuComponent,
    WholesaleMarketVolumesComponent,
    WholesaleMarketPricesComponent,
    MarketblockrightComponent,
    RetailMarketGeneralinfoComponent,
    RetailMarketMappriceComponent,
    ChartComponent,
    MapEuropeComponent,
    OSRComponent,
    PupComponent,
    WholesaleMarketInstallcapacityComponent,
    VDEGeneralComponent,
    DAMDemandComponent,
    WholesaleMarketBlocksComponent,
    ForecastRdnComponent,
    MarketblockrddComponent,
    ManufacturersComponent,
    TabletsComponent,
    MaininfoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          //useFactory : TranslationProviders , 
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      defaultLanguage: 'ua'
  })

  ],
  exports: [
      
  ],
  providers: [
      DatePipe,
      //{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
