
<!--<div class="ds-logo">
    <a href="#"><img src="../../../assets/image/logo.png"></a>
</div>-->

<a href="#"><img src="../../../assets/image/logo.png"></a>
<!--
<mat-form-field appearance="fill">
    <mat-select>
        <mat-option *ngFor="let lang of langs" [value]="lang.value">
        {{lang.viewValue}}
        </mat-option>
    </mat-select>
</mat-form-field>
-->

<div class= "lang">
    <select [(ngModel)]='langSelect' class='form-control lang-select' (change)="onChangeLang($event.target.value)">
        <option value='ua'>ua</option>
        <option value='ru'>ru</option>
        <option value='en'>en</option>
    </select>
    </div>
<!--
<div class="dropdown">
    <div class="dropbtn">RU</div>
    <div class="dropdown-content">
      <a href="#">RU</a>
      <a href="#">EN</a>
    </div>
  </div>
--> 
<span class="menu-link">{{menu_name | translate}} »</span>
<span class="submenu-link">{{submenu_name | translate}}</span>

<!--<div class="blink"></div><span>Проводиться тестування програмного комплексу із випробуванням програмних складових</span></div>-->
<!--<span class="blinking">Проводиться тестування програмного комплексу із випробуванням програмних складових</span>-->
<span class="normal">«Пакет EQUANT. Pro»
* У зв’язку з проведенням воєнних дій на території України деяка інформаціє може бути відсутня</span>
<button mat-button class="btn-logout" (click)="auth.logout()">Logout</button>

<!--
<span>My App</span>
<span class="example-spacer"></span>
<button class="btn-logout" (click)="auth.logout()">Logout</button>

<span>My App</span>
<span class="example-spacer"></span>
<span class="add-me">My App</span>

<span class="grow1">1</span>
<span class="grow2">2</span>
<span class="grow1">3</span>
-->