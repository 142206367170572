import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  menu_name: string;
  submenu_name: string;

  title = 'frontend';

  onNewMenuItem(menu:any)
  {
    if (menu != undefined)   {
      
      this.menu_name = menu[0];
      this.submenu_name = menu[1];
      console.log(this.menu_name)
      console.log(this.submenu_name)
    }
  }

  readLocalStorageValue(key)
  {
    let value =   localStorage.getItem(key);


    if(value == undefined || value == null)
    {
      value ='false';
    }
    else
    {
      value ='true';
    }
//     console.log(value);
     return value;  
  }  

  log(val)
  {
//    console.log(val)
  }
  
}

