import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_WHOLESALE_MARKET_PRICES_DOWNLOAD_AS_EXEL} from '../../api-endpoints';

@Component({
  selector: 'app-download-exel',
  templateUrl: './download-exel.component.html',
  styleUrls: ['./download-exel.component.css']
})
export class DownloadExelComponent implements OnInit {

  @Input('date_start') date_start;
  @Input('date_end') date_end;
  @Input('data_code') data_code;

  //@Input()
  //data_code: number;
  //date_start: string;
 // date_end: string;

  file_name: string;
  file_type: string = '.txt';


  constructor(private http: HttpClient) { }

  ngOnInit(): void {
 //   this.date_start = '';
 //   this.date_end = '';

//    this.file_name = `${this.date_start}-${this.date_end}-${this.data_code}${this.file_type}`
    this.file_name = `report.xlsx`
  }

  performDownload(): void {
    console.log(this.date_start)
    console.log(this.date_end)
    this.http.get<any>(API_WHOLESALE_MARKET_PRICES_DOWNLOAD_AS_EXEL, {
      responseType: 'blob' as 'json',
      params: {
        date_start: this.date_start,
        date_end: this.date_end,
        data_code: this.data_code
      }
    }).subscribe(response => this.saveFile(response))

  }

  saveFile(response) {

    const ds_arr = this.date_start.split('/')
    const de_arr = this.date_end.split('/')
    console.log(ds_arr)
    this.file_name = 'report_' + this.data_code + '_' +  ds_arr[2] +  ds_arr[1] +  ds_arr[0] + '-' + de_arr[2] +  de_arr[1] +  de_arr[0] + '.xlsx'

    const downloadURL = window.URL.createObjectURL(response);
    const link = document.createElement('a');

    link.href = downloadURL;
    link.download = this.file_name;
    link.click();
  }
}
